import { gql } from '@apollo/client';

export const GET_ALL_CHALLENGES = gql`
  query GET_ALL_CHALLENGES {
    fetchAllChallengesPage {
      created_on
      deadline
      id
      last_modified_on
      org
      orgData {
        bio
        discord
        email
        github
        kaggle
        linkedin
        name
        profileImage
        telegram
        twitter
        website
      }
      criterias {
        description
        score
        title
        id
      }
      reviewers {
        reviewer
        weight
        assignedCriterias
      }
      reward_amount
      reward_amount_usd
      reward_token
      coverImg
      sections {
        content
        name
        order
      }
      datasets {
        order
        name
        did
        content
      }
      status
      summary
      tags
      title
      type
      total_submissions
      participants
    }
  }
`;

export const GET_CHALLENGE_DETAILS = gql`
  query FetchChallengePage($id: Int!) {
    fetchChallengePage(id: $id) {
      id
      last_modified_on
      reward_amount
      reward_amount_usd
      reward_token
      sections {
        content
        name
        order
      }
      datasets {
        order
        name
        did
        content
      }
      status
      summary
      title
      tags
      type
      org
      orglogo
      deadline
      reviewers
      participants
    }
  }
`;

export const FETCH_DID_METADATA = gql`
  query MyQuery($did: String!) {
    fetchDidMetaData(did: $did) {
      id
      assetLink
      metadata {
        author
        description
        name
      }
    }
  }
`;

export const GET_DOWNLOADABLE_URL = gql`
  query GetDownloadableURL($did: String!) {
    getDownloadableURL(did: $did)
  }
`;
//MUTATIONS

export const CREATE_CHALLENGE_MUTATION = gql`
  mutation CreateChallenge(
    $orgProfileAddress: String!
    $challengeData: ChallengeData!
    $challengeDetails: ChallengeDetails!
    $draftId: String!
    $userId: String!
  ) {
    createChallenge(
      orgProfileAddress: $orgProfileAddress
      challengeData: $challengeData
      challengeDetails: $challengeDetails
      userId: $userId
      draftId: $draftId
    )
  }
`;

export const CREATE_DRAFT_CHALLENGE_MUTATION = gql`
  mutation createDraft(
    $orgProfileAddress: String!
    $challengeData: ChallengeData
    $challengeDetails: ChallengeDetails
  ) {
    createDraft(
      orgProfileAddress: $orgProfileAddress
      challengeData: $challengeData
      challengeDetails: $challengeDetails
    )
  }
`;

export const UPDATE_CHALLENGE_MUTATION = gql`
  mutation updateChallenge(
    $challengeId: Int!
    $orgProfileAddress: String!
    $challengeData: ChallengeData!
    $challengeDetails: ChallengeDetails!
  ) {
    updateChallenge(
      challengeId: $challengeId
      orgProfileAddress: $orgProfileAddress
      challengeData: $challengeData
      challengeDetails: $challengeDetails
    )
  }
`;

export const UPDATE_DRAFT_MUTATION = gql`
  mutation updateDraft(
    $challengeId: Int!
    $orgProfileAddress: String!
    $challengeData: ChallengeData!
    $challengeDetails: ChallengeDetails!
  ) {
    updateDraft(
      challengeId: $challengeId
      orgProfileAddress: $orgProfileAddress
      challengeData: $challengeData
      challengeDetails: $challengeDetails
    )
  }
`;

export const MARK_CHALLENGE_COMPLETE_MUTATION = gql`
  mutation markChallengeComplete($id: Int!) {
    markChallengeComplete(id: $id)
  }
`;

//SUBSCRIPTION

export const CREATE_CHALLENGE_SUBSCRIPTION = gql`
  subscription createChallengeSub($orgProfileAddress: String!) {
    createChallenge(orgProfileAddress: $orgProfileAddress) {
      data
      step
      message
    }
  }
`;

export const UPDATE_CHALLENGE_SUBSCRIPTION = gql`
  subscription updateChallengeSub($orgProfileAddress: String!) {
    updateChallenge(orgProfileAddress: $orgProfileAddress) {
      data
      step
      message
    }
  }
`;

export const GET_DOWNLOAD_URL_SUB = gql`
  subscription getDownloadableURLSub($did: String!) {
    getDownloadableURL(did: $did) {
      data
      step
      message
    }
  }
`;

export const UPDATE_CHALLENGE_PARTICIPANTS = gql`
  mutation UpdateChallengeParticipants($challengeId: ID!, $userId: ID!) {
    updateChallengeParticipants(challengeId: $challengeId, userId: $userId) {
      message
      success
    }
  }
`;
