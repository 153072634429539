import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Sidebar, useThemeMode } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
// import { ReactComponent as GoogleIcon } from '../../assets/google.svg';
// import { ReactComponent as GithubIcon } from '../../assets/github.svg';
// import { ReactComponent as LinkedInIcon } from '../../assets/linkedin.svg';
// import { ReactComponent as DiscordIcon } from '../../assets/discord.svg';
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import { SidebarProfileDropdown } from './SidebarProfileDropdown';
import { SidebarprofileHeader } from './Sidebarprofileheader';
import { RenderSidebarList } from './RenderSidebarList';
import { AppDispatch, Profile, RootState, sideNavItem } from '../../@types';
import { ReactComponent as LunorLogo } from '../../assets/Lunor.svg';
import { ReactComponent as LunorFullLogo } from '../../assets/LunorQuest.svg';
import { ReactComponent as LunorFullLogoWhite } from '../../assets/LunorQuestWhite.svg';
// import { isDesktop, isMobile } from 'react-device-detect';
import {
  guestNavBarOrgLists,
  GUEST_PROFILE_CREATE_PATH,
  orgSidebarOptions,
  userSidebarOptions,
  userNavBarOrgLists,
  USER_PROFILE_DETAILS_PATH,
  ORG_PROFILE_DETAILS_PATH,
  // SIGNUP_PATH,
  WALLET_CONNECT_WARNING_TEXT,
  WALLET_BUTTON_WARNING_TEXT,
  GUEST_CHALLENGE_LIST_PATH,
} from '../../Utils/Constants';
import CommonButton from '../../Components/CommonButton';

import {
  // fetchPasskeysFromDB,
  // fetchUserFromDB,
  fetchUserProfileData,
  fetchuserProfileId,
  getSocialUserWithJWTtoken,
  // updateOnChainUserProfile,
  // isPasskeyCreated,
} from '../../Utils/Helpers';
import { useAccount } from 'wagmi';
import { useQuery } from '@apollo/client';
import { ALL_ORGANISATIONS } from '../../graphql/orgs';
import ConnectWallet from '../../Components/ConnectWallet';
// import { ReactComponent as DarkLogo } from '../../assets/desights-logo-black.svg';
// import { ReactComponent as DLogo } from '../../assets/desights-D-black.svg';
// import { ReactComponent as LightLogo } from '../../assets/desights-new-logo-white.svg';
import {} from // signInWithDiscord,
// signInWithGithub,
// signInWithGoogle,
// signInWithLinkedIn,
'../../Utils/Config/supabase.config';
// import { createPasskey, loadPasskeysFromLocalStorage } from '../../Utils/passkeys';
// import { getAddressFromPasskey } from '../../Utils/Config/mintNFT';
// import { updateProfileDetails } from '../../Store/Slices/ProfileSlice';
import { updateInitialLoading } from '../../Store/Slices/UtilsSlice';
import {
  // toggleSignUpModalAction,
  toggleSignUpModalForInfoAction,
  // toggleSignUpModalForInfoAction,
} from '../../Store/Slices/MiscSlice';
// import CommonModal from '../../Components/CommonModal';
import { useDisconnect } from 'wagmi';
import { resetRootState } from '../../Store';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
// import { useSignInTracking } from '../../Utils/Hooks/useSignInTracking';
import CommonWarningModal from '../../Components/Modals/CommonWarningModal';
import {
  updateGuestStateAction,
  updateProfileDetailsAction,
  switchToUserProfileAction,
} from '../../Store/Actions/ProfileActions';
import { decodeJwtPayload, getCookie, setCookie } from '../../Utils/Helpers/CommonHelpers';
// import { IconType } from 'react-icons';
import debounce from 'lodash/debounce';
import UserInfoModal from '../../Components/Modals/UserInfoModal';
// import smallLogoWhite from '../../assets/desight-small-logo-white.png';

const useAuth = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  const defaultProfile = useSelector((state: RootState) => state.profile.defaultProfile);
  const { isConnected, address: userAddress } = useAccount();

  const clearAllAuth = useCallback(() => {
    // Set logging out flag before cleanup
    localStorage.setItem('isLoggingOut', 'true');

    // Save theme mode
    const themeMode = localStorage.getItem('flowbite-theme-mode');

    const activeNetwork = localStorage.getItem('@appkit/active_caip_network_id');
    const activeNamespace = localStorage.getItem('@appkit/active_namespace');
    const cbwsdkVersion = localStorage.getItem('-CBWSDK:VERSION');

    // Reset AppKit state
    // resetAppKit();

    // Clear all storage except theme
    localStorage.clear();
    sessionStorage.clear();

    localStorage.setItem('@appkit/connection_status', 'disconnected');

    // Restore theme mode
    if (themeMode) {
      localStorage.setItem('flowbite-theme-mode', themeMode);
    }
    if (activeNetwork) {
      localStorage.setItem('@appkit/active_caip_network_id', activeNetwork);
    }
    if (activeNamespace) {
      localStorage.setItem('@appkit/active_namespace', activeNamespace);
    }
    if (cbwsdkVersion) {
      localStorage.setItem('-CBWSDK:VERSION', cbwsdkVersion);
    }

    // Only clear socialLogin cookie
    setCookie('socialLogin', null);

    // Reset Redux state
    dispatch(resetRootState());
    dispatch(updateGuestStateAction(true));

    // Clear logging out flag after a delay
    setTimeout(() => {
      localStorage.removeItem('isLoggingOut');
    }, 1000);
  }, [dispatch]);

  // Add new state for initial loading
  const [initialAuthCheck, setInitialAuthCheck] = useState(false);

  const handleSignOut = useCallback(async () => {
    if (isLoggingOut) return;

    setIsLoggingOut(true);
    setLoading(true);
    setInitialAuthCheck(false); // Reset the auth check state

    try {
      localStorage.setItem('isLoggingOut', 'true');
      disconnect();
      clearAllAuth();
      navigate(GUEST_CHALLENGE_LIST_PATH, { replace: true });
    } catch (error) {
      console.error('Error during sign out:', error);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setIsLoggingOut(false);
        localStorage.removeItem('isLoggingOut');
      }, 1000);
    }
  }, [isLoggingOut, clearAllAuth, disconnect, navigate]);

  const handleLogin = useCallback(
    async (forSocialUser = false) => {
      if (isLoggingOut) return;

      dispatch(updateInitialLoading(true));
      try {
        const socialLoginCookie = getCookie('socialLogin');
        if (!socialLoginCookie) {
          throw new Error('No social login cookie found');
        }

        const userData = await getSocialUserWithJWTtoken(socialLoginCookie);
        if (!userData) {
          throw new Error('No user data found');
        }

        if (forSocialUser && userData.owner) return;

        const userProfileData = await fetchUserProfileData(userData.id);
        if (!userProfileData) {
          throw new Error('No profile data found');
        }

        dispatch(updateProfileDetailsAction({ ...userProfileData, id: userProfileData?.id }));
        dispatch(updateGuestStateAction(false));
        // @ts-expect-error mixed type
        if (!userProfileData.username) {
          dispatch(toggleSignUpModalForInfoAction(true));
        }
      } catch (error) {
        console.error('Error during login:', error);
        if (
          error.response?.status === 401 ||
          error.message.includes('No social login cookie found')
        ) {
          handleSignOut();
        }
      } finally {
        dispatch(updateInitialLoading(false));
      }
    },
    [isLoggingOut, dispatch, handleSignOut],
  );

  // Modify the useAuth hook's visibility change handler
  useEffect(() => {
    const handleVisibilityChange = async () => {
      // Remove the visibility check since we don't want to log out when tab is inactive
      if (isLoggingOut) return;

      const hasWallet = localStorage.getItem('currAddress');
      const socialLoginCookie = getCookie('socialLogin');

      // Only check if user is actively trying to use the site while logged out
      if (!hasWallet && !socialLoginCookie && !isGuest && document.visibilityState === 'visible') {
        const isUserInteracting = document.hasFocus(); // Check if user is actually interacting
        if (isUserInteracting) {
          handleSignOut();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [isLoggingOut, isGuest, handleSignOut]);

  // Modify the cookie monitoring effect
  useEffect(() => {
    const checkSocialLoginCookie = () => {
      const socialLoginCookie = getCookie('socialLogin');
      // Only log out if user is actively using the site and not in guest mode
      if (!socialLoginCookie && !isLoggingOut && !isGuest && document.hasFocus()) {
        const isUserInteracting = document.hasFocus();
        if (isUserInteracting) {
          handleSignOut();
        }
      }
    };

    // Reduce check frequency and only check when tab is active
    const intervalId = setInterval(() => {
      if (document.visibilityState === 'visible') {
        checkSocialLoginCookie();
      }
    }, 30000); // Check every 30 seconds instead of 5 seconds

    return () => clearInterval(intervalId);
  }, [isLoggingOut, isGuest, handleSignOut]);

  // Modify the auto-login effect to be less aggressive
  useEffect(() => {
    const checkAuth = async () => {
      if (isLoggingOut || initialAuthCheck) return;

      const socialLoginCookie = getCookie('socialLogin');
      const currAddress = localStorage.getItem('currAddress');
      const decodedJwt = decodeJwtPayload(socialLoginCookie);

      try {
        if (
          isConnected &&
          currAddress === userAddress &&
          defaultProfile.id !== decodedJwt?.id &&
          socialLoginCookie
        ) {
          await handleLogin();
        } else if (!isConnected && socialLoginCookie && defaultProfile.id !== decodedJwt?.id) {
          await handleLogin(true);
        } else if (!currAddress && document.hasFocus()) {
          // Only disconnect if user is actively using the site
          disconnect();
          dispatch(updateGuestStateAction(true));
        }
      } finally {
        setInitialAuthCheck(true);
      }
    };

    // Only check auth when the tab becomes active
    if (document.visibilityState === 'visible') {
      checkAuth();
    }
  }, [isConnected, userAddress, defaultProfile.id, isLoggingOut, initialAuthCheck]);

  return {
    isLoggingOut,
    loading,
    handleSignOut,
    handleLogin,
    initialAuthCheck,
  };
};

const SideBar = ({ className }) => {
  const isOrgSelected = useSelector((state: RootState) => state.utils.isOrgSelected);
  const currentProfile = useSelector((state: RootState) => state.profile.currentProfile);
  const defaultProfile = useSelector((state: RootState) => state.profile.defaultProfile);
  const showSignUpModal = useSelector((state: RootState) => state.misc.showSignUpModal);
  const showSignUpModalForInfo = useSelector(
    (state: RootState) => state.misc.showSignUpModalForInfo,
  );
  // const [loginError, setLoginError] = useState(false);

  // const [infoSubmitted, setInfoSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [provider, setProvider] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const { mode } = useThemeMode();

  const [organizationList, setAllOrganizationList] = useState([]);
  const { data: allOrg } = useQuery(ALL_ORGANISATIONS, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    // console.log('showSignUpModal', showSignUpModal);
  }, [showSignUpModal]);
  // const { disconnect } = useDisconnect();
  useEffect(() => {
    if (allOrg?.fetchAllOrganisationsPage?.length > 0) {
      setAllOrganizationList(
        allOrg.fetchAllOrganisationsPage?.map((org) => {
          return {
            ...org,
            ...org?.profile,
            isOrganization: true,
            profileId: org?.id,
          };
        }),
      );
    }
  }, [allOrg]);

  const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  const [currentUserProfiles, setCurrentUserProfiles] = useState<Profile[]>([]);
  const [enableProfileDropdown, setEnableProfileDropdown] = React.useState(false);
  const [renderSidebar, setRenderSidebar] = React.useState<sideNavItem[]>([]);

  const [showListselected, setShowListSelected] = React.useState<{
    login: boolean;
    key: number;
    imgSrc: string;
  }>({
    login: true,
    key: 0,
    imgSrc: 'https://m.media-amazon.com/images/I/51VOjZGpinL._UX569_.jpg',
  });
  // const navigate = useNavigate();
  const { isConnected, address: userAddress } = useAccount();
  const [showCreateButton, setShowCreateButton] = useState(false);
  useEffect(() => {
    //
    const user = localStorage.getItem('selecteduser');
    if (!isGuest && user) {
      setShowListSelected({
        ...showListselected,
        key: parseInt(user),
        login: !parseInt(user),
      });
    }
  }, [isGuest]);

  useEffect(() => {
    // console.log('defaultProfile', defaultProfile);
    if (isGuest) setRenderSidebar(guestNavBarOrgLists);
    else if (isOrgSelected)
      setRenderSidebar([
        {
          path: [ORG_PROFILE_DETAILS_PATH, currentProfile.profileId].join('/'),
          name: 'Profile',
          icon: FaUser,
        },
        ...orgSidebarOptions,
      ]);
    else
      setRenderSidebar([
        {
          path: [USER_PROFILE_DETAILS_PATH, defaultProfile.username].join('/'),
          name: 'My Profile',
          icon: FaUser,
        },
        ...userSidebarOptions,
      ]);
  }, [
    isOrgSelected,
    isGuest,
    defaultProfile.profileId,
    currentProfile.profileId,
    defaultProfile.username,
  ]);

  useEffect(() => {
    if (defaultProfile.organizations.length) {
      setCurrentUserProfiles(
        organizationList.filter((org: Profile) => defaultProfile?.organizations?.includes(org.id)),
      );
    } else {
      setCurrentUserProfiles([]);
    }
  }, [defaultProfile, organizationList, currentProfile]);

  const showCreateButtonHandler = async () => {
    // TO SHOW CREATE PROFILE BUTTON IN SIDE BAR IF WALLET IS CONNECTED AND NO USERID IS FOUND
    const userProfileId = await fetchuserProfileId(userAddress);
    userProfileId ? setShowCreateButton(false) : setShowCreateButton(true);
  };

  useEffect(() => {
    isConnected && showCreateButtonHandler();
  }, [isConnected, userAddress]);

  // sidebar toggle
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth >= 1024);
  const [isCurrentlyMobile, setIsCurrentlyMobile] = useState(window.innerWidth < 1024);

  // console.log('isCurrentlyMobile', isCurrentlyMobile);
  // console.log('isCollapsed', isCollapsed);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    if (!sidebarOpen && isCurrentlyMobile) {
      setIsCollapsed(false);
    }
  };
  const sidebarRef = useRef(null);
  const observerRef = useRef<ResizeObserver | null>(null);

  const debouncedResize = useCallback(
    debounce((entries: ResizeObserverEntry[]) => {
      const width = entries[0]?.contentRect.width;
      if (width) {
        const isMobile = window.innerWidth < 1024;
        setIsCurrentlyMobile(isMobile);
        // Only update collapse state when switching from desktop to mobile
        if (isMobile) {
          setIsCollapsed(false);
        }
      }
    }, 100),
    [],
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        const toggleButton = document.querySelector('[data-drawer-toggle="default-sidebar"]');
        const desktopToggleButton = document.querySelector('[data-desktop-toggle="sidebar"]');

        if (
          (toggleButton && toggleButton.contains(event.target)) ||
          (desktopToggleButton && desktopToggleButton.contains(event.target))
        ) {
          return;
        }

        // Handle mobile view
        if (window.innerWidth < 1024 && sidebarOpen) {
          setSidebarOpen(false);
        }
        // Handle desktop view - collapse sidebar when clicking outside
        else if (window.innerWidth >= 1024 && !isCollapsed) {
          setIsCollapsed(true);
        }
      }
    };

    // Set up ResizeObserver
    if (sidebarRef.current) {
      observerRef.current = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          debouncedResize(entries);
        });
      });

      observerRef.current.observe(sidebarRef.current);
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      debouncedResize.cancel();
    };
  }, [sidebarOpen, isCollapsed, debouncedResize]);

  // const { isLoggingOut, handleSignOut, handleLogin, initialAuthCheck } = useAuth();
  const { handleSignOut } = useAuth();

  // Simplified desktop collapse handler
  const handleDesktopCollapse = () => {
    if (window.innerWidth >= 1024) {
      setIsCollapsed(!isCollapsed);
    }
  };

  const [showWarningModal, setShowWarningModal] = useState(false);

  // const handleCreateAccount = () => {
  //   if (isCurrentlyMobile) {
  //     setShowWarningModal(true);
  //     return;
  //   }
  //   navigate(SIGNUP_PATH);
  // };

  const location = useLocation();

  useEffect(() => {
    // Force user profile when on gems page
    if (location.pathname === '/gems' && isOrgSelected && defaultProfile) {
      dispatch(switchToUserProfileAction(defaultProfile));
    }
  }, [location.pathname, isOrgSelected, defaultProfile]);

  // Add this effect to handle email auto-fill when modal opens

  // Add this effect to handle menu visibility based on wallet connection
  useEffect(() => {
    if (!isConnected) {
      // Reset profile-related states immediately on disconnect
      setEnableProfileDropdown(false);
      setCurrentUserProfiles([]);
      dispatch(updateGuestStateAction(true));

      // Force sidebar to show guest view
      setRenderSidebar(guestNavBarOrgLists);
    }
  }, [isConnected, dispatch]);

  return (
    <>
      <div
        className={`h-full relative transition-all duration-300 ${
          isCollapsed && !isCurrentlyMobile ? 'w-20' : 'w-auto lg:w-64'
        } ${className}`}
      >
        <button
          onClick={toggleSidebar}
          data-drawer-target='default-sidebar'
          data-drawer-toggle='default-sidebar'
          aria-controls='default-sidebar'
          type='button'
          className={`fixed top-1/2 -translate-y-1/2 z-50 inline-flex items-center justify-center p-1 text-sm text-lightTextColor rounded-full lg:hidden bg-primary-50 dark:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600 w-6 h-6 transition-all duration-300`}
          style={{
            left: sidebarOpen ? '264px' : '10px',
          }}
        >
          {sidebarOpen ? <HiChevronLeft size={16} /> : <HiChevronRight size={16} />}
        </button>

        <button
          onClick={handleDesktopCollapse}
          data-desktop-toggle='sidebar'
          className='hidden lg:flex fixed top-1/2 -translate-y-1/2 z-50 items-center justify-center w-6 h-6 rounded-full text-lightTextColor bg-primary-50 dark:bg-primary-600 cursor-pointer transition-all duration-300'
          style={{
            left: isCollapsed ? '72px' : '245px',
          }}
        >
          {isCollapsed ? <HiChevronRight size={16} /> : <HiChevronLeft size={16} />}
        </button>

        <aside
          ref={sidebarRef}
          className={`fixed top-0 left-0 z-40 h-screen transition-all duration-300 ${
            sidebarOpen ? '' : '-translate-x-full lg:translate-x-0'
          } ${isCollapsed && !isCurrentlyMobile ? 'w-20' : 'w-64'}`}
          id='default-sidebar'
          aria-label='Sidenav'
        >
          <Sidebar
            aria-label='Sidebar with multi-level dropdown example'
            className={`h-full overflow-y-auto ${isCollapsed && !isCurrentlyMobile ? '!w-20 px-0' : ''}`}
            id='sidebar'
          >
            <Sidebar.CTA className={`!p-0 ${isCollapsed && !isCurrentlyMobile ? '!w-20' : ''}`}>
              <div className='flex justify-center items-center dark:bg-primary-800'>
                <div className={`w-full ${isCollapsed && !isCurrentlyMobile ? 'px-2' : ''}`}>
                  {isGuest && location.pathname !== GUEST_PROFILE_CREATE_PATH ? (
                    <>
                      {showCreateButton && isConnected && (
                        // <CommonButton
                        //   buttonText={isCollapsed && !isCurrentlyMobile ? '+' : 'Create My Account'}
                        //   buttonClassName='w-full'
                        //   onClick={handleCreateAccount}
                        // />
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <SidebarprofileHeader
                        showListselected={showListselected}
                        currentUserOrg={currentUserProfiles}
                        setEnableProfileDropdown={setEnableProfileDropdown}
                        enableProfileDropdown={enableProfileDropdown}
                        isCollapsed={isCollapsed && !isCurrentlyMobile}
                      />
                      {enableProfileDropdown && !isCollapsed && (
                        <SidebarProfileDropdown
                          ProfileUserData={[defaultProfile, ...currentUserProfiles]}
                          showListselected={showListselected}
                          setEnableProfileDropdown={setEnableProfileDropdown}
                          isCollapsed={isCollapsed && !isCurrentlyMobile}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Sidebar.CTA>

            {!isGuest && (
              <hr className='border-lightBorderColor dark:border-darkBorderColor my-2' />
            )}

            <Sidebar.Items>
              <Sidebar.ItemGroup className={isCollapsed && !isCurrentlyMobile ? '!space-y-2' : ''}>
                {!isGuest && isConnected && (
                  <RenderSidebarList
                    setSidebarOpen={setSidebarOpen}
                    renderSidebar={renderSidebar}
                    isCollapsed={isCollapsed && !isCurrentlyMobile}
                  />
                )}
                {!isOrgSelected && (
                  <>
                    <hr className='border-lightBorderColor sm:mx-auto dark:border-darkBorderColor my-2' />
                    <RenderSidebarList
                      setSidebarOpen={setSidebarOpen}
                      renderSidebar={isGuest ? guestNavBarOrgLists : userNavBarOrgLists}
                      isCollapsed={isCollapsed && !isCurrentlyMobile}
                    />
                  </>
                )}
                <hr className='border-lightBorderColor sm:mx-auto dark:border-darkBorderColor my-2' />

                <div
                  className={`flex ${isCollapsed && !isCurrentlyMobile ? 'justify-center' : 'justify-between'} items-center pt-1`}
                >
                  <div
                    className={`flex flex-col ${isCollapsed && !isCurrentlyMobile ? 'items-center w-full' : 'items-center'}`}
                  >
                    {/* {isConnected && (
                      <ConnectWallet
                        buttonText={isCollapsed && !isCurrentlyMobile ? '' : 'Connect Wallet'}
                        responsive={true}
                        // setLoginError={setLoginError}
                        isCollapsed={isCollapsed && !isCurrentlyMobile}
                        loading={loading}
                      />
                    )}
                    <div
                      className={`${isCollapsed && !isCurrentlyMobile ? 'mx-auto justify-center items-center' : 'ml-4'} mt-4 mb-[80px] flex justify-center`}
                    >
                      {currentProfile.id === '' && currentProfile.profileId === '' ? (
                        <ConnectWallet
                          buttonText={isCollapsed && !isCurrentlyMobile ? '' : 'Sign In'}
                          responsive={true}
                          // setLoginError={setLoginError}
                          isCollapsed={isCollapsed && !isCurrentlyMobile}
                          loading={loading}
                        />
                      ) : (
                        <CommonButton
                          buttonText={isCollapsed && !isCurrentlyMobile ? '' : 'Sign Out'}
                          buttonClassName={isCollapsed && !isCurrentlyMobile ? 'p-1.5' : ''}
                          size={isCollapsed && !isCurrentlyMobile ? 'xs' : 'md'}
                          ButtonIcon={FaSignOutAlt}
                          onClick={handleSignOut}
                        />
                      )} */}
                    <ConnectWallet
                      buttonText={isCollapsed && !isCurrentlyMobile ? '' : 'Connect Wallet'}
                      responsive={true}
                      // setLoginError={setLoginError}
                      isCollapsed={isCollapsed && !isCurrentlyMobile}
                      loading={loading}
                      addressShow={false}
                    />
                    <div
                      className={`${isCollapsed && !isCurrentlyMobile ? 'mx-auto justify-center items-center' : 'ml-4'} mt-4 mb-[80px] flex justify-center`}
                    >
                      {isConnected && (
                        <CommonButton
                          buttonText={isCollapsed && !isCurrentlyMobile ? '' : 'Sign Out'}
                          buttonClassName={isCollapsed && !isCurrentlyMobile ? 'p-1.5' : ''}
                          size={isCollapsed && !isCurrentlyMobile ? 'xs' : 'md'}
                          ButtonIcon={FaSignOutAlt}
                          onClick={handleSignOut}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </Sidebar>

          <div
            className={`${
              isCollapsed ? 'w-20' : 'w-60'
            } bg-white fixed text-center bottom-0 left-0 dark:bg-primary-800 py-2 transition-all duration-300`}
          >
            {isCollapsed ? (
              // <DLogo className='mx-auto w-9 h-9' />
              <LunorLogo className='mx-auto w-7 h-7' />
            ) : mode === 'dark' ? (
              // <DarkLogo
              //   style={{ marginLeft: 'auto', marginRight: 'auto' }}
              //   className='desights-logo'
              // />
              <LunorFullLogoWhite className='mx-auto w-[220px]' />
            ) : (
              // <LightLogo
              //   style={{ marginLeft: 'auto', marginRight: 'auto' }}
              //   className='desights-logo bg-white'
              // />
              <LunorFullLogo className='mx-auto w-[220px]' />
            )}
          </div>
        </aside>

        {sidebarOpen && (
          <div className='fixed top-0 left-0 w-screen h-screen bg-transparent z-30' />
        )}
        <UserInfoModal showSignUpModalForInfo={showSignUpModalForInfo} setLoading={setLoading} />
      </div>

      <CommonWarningModal
        buttonText={WALLET_BUTTON_WARNING_TEXT}
        show={showWarningModal}
        warningText={WALLET_CONNECT_WARNING_TEXT}
        onButtonClick={() => {
          setShowWarningModal(false);
        }}
      />
    </>
  );
};

export default SideBar;
