import axios from 'axios';
import { AppDispatch, Challenge, Profile, ProfilePersonalInfo } from '../../@types';
import store from '../../Store';
import { toggleTxnModalAction } from '../../Store/Actions/UtilsActions';
import {
  addOrgToProfile,
  updateCurrentProfile,
  updateProfileDetails,
} from '../../Store/Slices/ProfileSlice';
import {
  completeTxnSteps,
  errorTxnSteps,
  nextTxnStep,
  toggleTxnModal,
  updateOrgSelected,
} from '../../Store/Slices/UtilsSlice';
import {
  ADD_ADMIN_TXNS,
  CHALLENGE_PATH,
  CREATE_CHALLENGE_TXNS,
  CREATE_ORG_PROFILE_TXNS,
  CREATE_USER_PROFILE_TXNS,
  ORG_PROFILE_DETAILS_PATH,
  REMOVE_ADMIN_TXNS,
  UPDATE_CHALLENGE_TXNS,
  UPDATE_ORG_PROFILE_TXNS,
  UPDATE_USER_PROFILE_TXNS,
  USER_PROFILE_DETAILS_PATH,
  ADD_ADMIN_TO_USER_TXNS,
  REMOVE_ADMIN_FROM_USER_TXNS,
  GET_DOWNLOAD_URL,
} from '../Constants';

import { apolloClient } from '../../App';
import {
  CREATE_ORG_PROFILE,
  UPDATE_ORG_PROFILE,
  CREATE_ORG_PROFILE_SUBSCRIPTION,
  UPDATE_ORG_PROFILE_SUBSCRIPTION,
  ADD_ADMIN_TO_ORG_SUBSCRIPTION,
  ADD_ADMIN_TO_ORG,
  REMOVE_ADMIN_FROM_ORG,
  REMOVE_ADMIN_FROM_ORG_SUBSCRIPTION,
  FETCH_ORG_DETAILS,
  ALL_ORGANISATIONS,
  FETCH_ALL_ORG_IDS,
  FETCH_ORG_METRICS,
} from '../../graphql/orgs';
import { subscriptionToEvent } from './SubscriptionHelpers';
import {
  UPDATE_USER_SUBSCRIPTION,
  UPDATE_USER_PROFILE_DETAILS,
  FETCH_USER_PROFILE_DETAILS,
  GET_ORGANIZATIONS_FOR_USER,
  FETCH_USER_ADDRESS,
  IS_USER_PROFILE_REGISTERED,
  FETCH_ALL_ADMINS,
  FETCH_ALL_ORGS_FOR_USER,
  CREATE_USER_SUBSCRIPTION,
  CREATE_USER_PROFILE,
  CheckIsWalletInvited,
  FETCH_SUBMISSION_DETAILS,
  FETCH_USER_PROFILE_DATA,
  ADD_ADMIN_TO_USER,
  ADD_ADMIN_TO_USER_PROFILE_SUB,
  REMOVE_ADMIN_OF_USER_PROFILE_SUB,
  REMOVE_ADMIN_OF_USER,
  GET_ADMINS_WITH_INFO,
  FETCH_ALL_ADMINS_FOR_USER,
  CREATE_USER_IN_DB,
  FETCH_USER_FROM_DB,
  FETCH_PASSKEYS_FROM_DB,
  GET_SOCIAL_USER_WITH_JWTTOKEN,
  VERIFY_SIGNATURE_AND_RETURN_ADDRESS,
  GET_USER_GEMS,
  CHECK_USERNAME_AVAILABILITY,
} from '../../graphql/users';

import {
  CREATE_CHALLENGE_MUTATION,
  CREATE_CHALLENGE_SUBSCRIPTION,
  CREATE_DRAFT_CHALLENGE_MUTATION,
  // CREATE_DRAFT_CHALLENGE_MUTATION,
  FETCH_DID_METADATA,
  GET_ALL_CHALLENGES,
  GET_CHALLENGE_DETAILS,
  GET_DOWNLOADABLE_URL,
  GET_DOWNLOAD_URL_SUB,
  UPDATE_CHALLENGE_MUTATION,
  UPDATE_CHALLENGE_SUBSCRIPTION,
  UPDATE_DRAFT_MUTATION,
} from '../../graphql/challenges';
import { NavigateFunction } from 'react-router-dom';
import { getAPIMappedChallenge } from './FormHelper';
import { resetSidebarSyncDetailsAction, updateGuestStateAction } from '../../Store/Actions';
import { addOrganization } from '../../Store/Slices/OrganizationSlice';
import {
  ALL_SUBMISSIONS_FOR_CHALLENGE,
  CREATE_SUBMISSION_MUTATION,
  CREATE_SUBMISSION_SUBSCRIPTION,
  CREATE_TEMP_SUBMISSION_MUTATION,
  FETCH_ALL_SUBMISSIONS_FOR_USER,
} from '../../graphql/submissions';
import { OrgProfileData } from '../../@types/graphQl/org';
import { User } from '@supabase/supabase-js';
import { useLazyQuery } from '@apollo/client';
// import { debounce } from 'lodash';

const dispatch = store.dispatch;

// Event listeners will update the redux.
const onTransactionNextEvent = (data: string) => {
  // Data discussion
  console.log(`122 next`, data);
  dispatch(nextTxnStep(data));
};

const onTransactionCompleteEvent = (data: string) => {
  // Data discussion
  console.log(`122 complete`, data);
  dispatch(completeTxnSteps(data));
};

const onTransactionErrorEvent = (data?: string) => {
  // Data discussion
  console.log(`122 error`, data);
  dispatch(errorTxnSteps({}));
};

export const tempStepMover = (steps: string, onComplete: () => void) => {
  // Temporary function
  const isSuccess = true;
  let step = 1;
  const clear = () => clearInterval(interval);
  const interval = setInterval(() => {
    if (step < steps.length) {
      dispatch(nextTxnStep(''));
      step++;
    } else {
      if (isSuccess) {
        onComplete();
        dispatch(completeTxnSteps(''));
        setTimeout(() => {
          dispatch(toggleTxnModal(''));
        }, 2000);
      } else {
        dispatch(errorTxnSteps(''));
      }
      clear();
    }
  }, 200);
};

export const fetchUserPage = async (id: string) => {
  const { data } = await apolloClient.query({
    query: FETCH_USER_PROFILE_DETAILS,
    variables: {
      id: id,
    },
  });
  return data?.fetchUserPage;
};

export const checkUsernameAvailabilityAPI = (
  successCallback: (data: { isUsernameAvailable: boolean }) => void,
  errorCallback: (error) => void,
) => {
  const [checkUsernameAvailability] = useLazyQuery(CHECK_USERNAME_AVAILABILITY, {
    onCompleted: (data) => {
      successCallback(data);
    },
    onError: (error) => {
      errorCallback(error);
    },
    fetchPolicy: 'network-only',
  });
  return checkUsernameAvailability;
};
let timeout;
export const debouncedCheck = (callback, milliseconds) => {
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback(...args), milliseconds);
  };
};

// write calls
export const createOnChainUserProfile = async (
  userData: Profile,
  address: string,
  navigate: NavigateFunction,
  dispatch: AppDispatch,
) => {
  // Datatype discussion
  const {
    name = '',
    email = '',
    bio = '',
    profileImage = '',
    website = '',
    twitter = '',
    discord = '',
    telegram = '',
    kaggle = '',
    github = '',
    linkedin = '',
  } = userData;

  const onChainProfileData = {
    name,
    email,
    bio,
    profileImage,
    website,
    twitter,
    discord,
    telegram,
    kaggle,
    github,
    linkedin,
  };

  dispatch(toggleTxnModalAction(CREATE_USER_PROFILE_TXNS));

  const updateFn = (id: string) => {
    dispatch(updateGuestStateAction(false));
    dispatch(updateProfileDetails({ ...userData, profileId: id }));
    dispatch(updateOrgSelected(false));
    dispatch(updateGuestStateAction(false));
    dispatch(resetSidebarSyncDetailsAction());
    navigate(USER_PROFILE_DETAILS_PATH + '/' + id);
  };

  const subscription = subscriptionToEvent(
    CREATE_USER_SUBSCRIPTION,
    onTransactionNextEvent,
    onTransactionErrorEvent,
    updateFn,
    {
      controller: address,
    },
    'newUserProfile',
    onTransactionCompleteEvent,
  );
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_USER_PROFILE,
      variables: {
        controller: address,
        profileData: onChainProfileData,
      },
    });

    if (response) {
      subscription.unsubscribe();
    }
  } catch (error) {
    subscription.unsubscribe();
    console.log(error);
  }
};

export const createOnChainOrgProfile = async (
  orgData: Profile,
  userProfileId: string,
  navigate: NavigateFunction,
) => {
  // Datatype discussion
  const {
    name = '',
    email = '',
    bio = '',
    profileImage = '',
    website = '',
    twitter = '',
    discord = '',
    telegram = '',
    industry = '',
    github = '',
    linkedin = '',
  } = orgData;
  dispatch(toggleTxnModalAction(CREATE_ORG_PROFILE_TXNS));
  const onChainProfileData = {
    name,
    email,
    bio,
    profileImage,
    website,
    twitter,
    discord,
    telegram,
    industry,
    github,
    linkedin,
  };

  const updateFn = (id: string) => {
    dispatch(addOrganization({ ...orgData, id }));
    dispatch(addOrgToProfile(id));
    dispatch(updateCurrentProfile({ ...orgData, id, profileId: id }));
    dispatch(updateOrgSelected(true));
    navigate(ORG_PROFILE_DETAILS_PATH + '/' + id);

    try {
      //READING AND UPDATING
      const oldAdminsOfUser = apolloClient.readQuery({
        query: FETCH_ALL_ORGS_FOR_USER,
        variables: {
          profileAddress: userProfileId,
        },
      });
      let updatedOrgs = [];
      if (oldAdminsOfUser.fetchAllOrganisationsForGivenUser) {
        updatedOrgs = [...oldAdminsOfUser.fetchAllOrganisationsForGivenUser, id];
      }

      const oldAllOrganisations = apolloClient.readQuery({
        query: ALL_ORGANISATIONS,
      });
      let updatedAllOrganisations;
      if (oldAllOrganisations) {
        updatedAllOrganisations = [
          ...oldAllOrganisations.fetchAllOrganisationsPage,
          {
            id: id,
            profile: { ...onChainProfileData },
            challenges: [],
            activeChallengesCount: 0,
            admins: [userProfileId],
            manager: [''],
            totalRewardAmountInUsd: 0,
            totalSubmissions: 0,
            profileId: id,
          },
        ];
      } else {
        updatedAllOrganisations = [
          {
            id: id,
            profile: { ...onChainProfileData },
            challenges: [],
            activeChallengesCount: 0,
            admins: [userProfileId],
            manager: [''],
            totalRewardAmountInUsd: 0,
            totalSubmissions: 0,
            profileId: id,
          },
        ];
      }

      // WRITING IN CACHE

      apolloClient.writeQuery({
        query: FETCH_ALL_ORGS_FOR_USER,
        variables: {
          profileAddress: userProfileId,
        },
        data: {
          fetchAllOrganisationsForGivenUser: updatedOrgs,
        },
      });

      apolloClient.writeQuery({
        query: ALL_ORGANISATIONS,
        data: {
          fetchAllOrganisationsPage: updatedAllOrganisations,
        },
      });
    } catch (er) {
      console.log(er);
    }
  };

  const sub = subscriptionToEvent(
    CREATE_ORG_PROFILE_SUBSCRIPTION,
    onTransactionNextEvent,
    onTransactionErrorEvent,
    updateFn,
    {
      userProfileAddress: userProfileId,
    },
    'newOrgProfile',
    onTransactionCompleteEvent,
  );
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_ORG_PROFILE,
      variables: {
        userProfileAddress: userProfileId,
        profileData: onChainProfileData,
      },
      refetchQueries: [
        {
          query: ALL_ORGANISATIONS,
          errorPolicy: 'all',
        },
        {
          query: GET_ORGANIZATIONS_FOR_USER,
          variables: {
            profileAddress: userProfileId,
          },
        },
      ],
    });
    if (response) {
      // sub.unsubscribe();
      // updateFn(response.data.createOrgProfile)
      onTransactionCompleteEvent('complete');
    }
  } catch (error) {
    console.log(error);
    sub.unsubscribe();
    onTransactionErrorEvent();
    throw error;
  }
};

export const updateOnChainUserProfile = async (
  userData: Profile,
  onComplete: () => void,
  navigate: NavigateFunction,
) => {
  const {
    name = '',
    email = '',
    bio = '',
    profileImage = '',
    website = '',
    twitter = '',
    discord = '',
    telegram = '',
    kaggle = '',
    github = '',
    linkedin = '',
    firstname = '',
    lastname = '',
    username = '',
    payment_wallet = '',
    country = '',
    newsLastSeen = 1,
  } = userData;

  dispatch(toggleTxnModalAction(UPDATE_USER_PROFILE_TXNS));

  const onChainProfileData = {
    name,
    email,
    bio,
    profileImage,
    website,
    twitter,
    discord,
    telegram,
    kaggle,
    github,
    linkedin,
    firstname,
    lastname,
    payment_wallet,
    country,
    news_last_seen: newsLastSeen,
    username,
  };

  const updateFn = () => {
    navigate(USER_PROFILE_DETAILS_PATH + '/' + userData?.username);
    dispatch(updateOrgSelected(false));
    dispatch(updateProfileDetails(userData));

    const oldProfileData = apolloClient.readQuery({
      query: FETCH_USER_PROFILE_DETAILS,
      variables: {
        id: userData?.profileId,
      },
    });

    const updatedData = {
      ...oldProfileData?.fetchUserPage,
      data: {
        ...oldProfileData?.fetchUserPage.data,
        bio,
        discord,
        email,
        github,
        kaggle,
        linkedin,
        name,
        profileImage,
        telegram,
        twitter,
        website,
      },
      username,
    };
    apolloClient.writeQuery({
      query: FETCH_USER_PROFILE_DETAILS,
      variables: {
        id: userData?.profileId,
      },
      data: {
        fetchUserPage: updatedData,
      },
    });
  };

  try {
    subscriptionToEvent(
      UPDATE_USER_SUBSCRIPTION,
      onTransactionNextEvent,
      onTransactionErrorEvent,
      updateFn,
      {
        profileAddress: userData?.profileId,
      },
      'updateUserProfile',
      onTransactionCompleteEvent,
    );

    const response = await apolloClient.mutate({
      mutation: UPDATE_USER_PROFILE_DETAILS,
      variables: {
        profileAddress: userData?.profileId,
        newProfileData: onChainProfileData,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateOnChainOrgProfile = async (orgData: Profile, navigate: NavigateFunction) => {
  console.log('orgData', orgData);

  // Datatype discussion
  const {
    name = '',
    email = '',
    bio = '',
    profileImage = '',
    website = '',
    twitter = '',
    discord = '',
    telegram = '',
    industry = '',
    github = '',
    linkedin = '',
    profileId: profileAddress = '',
  } = orgData;

  dispatch(toggleTxnModalAction(UPDATE_ORG_PROFILE_TXNS));

  const onChainProfileData = {
    name,
    email,
    bio,
    profileImage,
    website,
    twitter,
    discord,
    telegram,
    industry,
    github,
    linkedin,
  };

  const updateFn = () => {
    const prevOrgData = apolloClient.readQuery({
      query: FETCH_ORG_DETAILS,
      variables: {
        profileAddress: profileAddress,
      },
    });

    const allPrevOrgs = apolloClient.readQuery({
      query: ALL_ORGANISATIONS,
    });

    const allOrgsExceptEdited = allPrevOrgs?.fetchAllOrganisationsPage?.filter(
      (org) => org?.id !== profileAddress,
    );
    const orgBeingUpdated = allPrevOrgs?.fetchAllOrganisationsPage?.filter(
      (org) => org?.id == profileAddress,
    );
    const newDataofOrg = { ...orgBeingUpdated[0], profile: onChainProfileData };
    const newOrgsToAdded = [...allOrgsExceptEdited, newDataofOrg];

    dispatch(updateCurrentProfile(orgData));
    dispatch(resetSidebarSyncDetailsAction());

    const newDataTOSaveInOrg = {
      ...prevOrgData.fetchOrgPage,
      profile: {
        name: name,
        email: email,
        bio: bio,
        profileImage: profileImage,
        website: website,
        twitter: twitter,
        discord: discord,
        telegram: telegram,
        industry: industry,
        github: github,
        linkedin: linkedin,
      },
    };

    apolloClient.writeQuery({
      query: FETCH_ORG_DETAILS,
      variables: {
        profileAddress: profileAddress,
      },
      data: {
        fetchOrgPage: newDataTOSaveInOrg,
      },
    });

    apolloClient.writeQuery({
      query: ALL_ORGANISATIONS,
      data: {
        fetchAllOrganisationsPage: newOrgsToAdded,
      },
    });

    navigate(ORG_PROFILE_DETAILS_PATH + '/' + orgData.profileId);
  };

  try {
    subscriptionToEvent(
      UPDATE_ORG_PROFILE_SUBSCRIPTION,
      onTransactionNextEvent,
      onTransactionErrorEvent,
      updateFn,
      {
        orgProfileAddress: profileAddress,
      },
      'updateOrgProfile',
      onTransactionCompleteEvent,
    );

    const response = await apolloClient.mutate({
      mutation: UPDATE_ORG_PROFILE,
      variables: {
        orgProfileAddress: profileAddress,
        newProfileData: onChainProfileData,
      },
    });
    console.log('Response from update mutation org:', response);

    // return response.data.createOrgProfile;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addAdminToOrg = async (
  orgProfileId: string,
  adminToAdd: string,
  onComplete: () => void,
) => {
  //USING GQL
  dispatch(toggleTxnModalAction(ADD_ADMIN_TXNS));

  const updateFn = () => {
    // const oldAdmins = apolloClient.readQuery({
    //   query: FETCH_ALL_ADMINS,
    //   variables: {
    //     profileAddress: orgProfileId,
    //   },
    // });
    // const updatedAdmins = [...oldAdmins.fetchAllAdmins, adminToAdd];
    // apolloClient.writeQuery({
    //   query: FETCH_ALL_ADMINS,
    //   variables: {
    //     profileAddress: orgProfileId,
    //   },
    //   data: {
    //     fetchAllAdmins: updatedAdmins,
    //   },
    // });
    onComplete();
  };

  try {
    subscriptionToEvent(
      ADD_ADMIN_TO_ORG_SUBSCRIPTION,
      onTransactionNextEvent,
      onTransactionErrorEvent,
      updateFn,
      {
        orgProfileAddress: orgProfileId,
      },
      'addAdminToOrg',
      onTransactionCompleteEvent,
    );

    const res = await apolloClient.mutate({
      mutation: ADD_ADMIN_TO_ORG,
      variables: {
        adminToAdd: adminToAdd,
        orgProfileAddress: orgProfileId,
      },
      refetchQueries: [
        {
          query: GET_ADMINS_WITH_INFO,
          variables: {
            profileAddress: orgProfileId,
          },
        },
      ],
    });
    res && console.log(res);
  } catch (er) {
    console.log(er);
    onTransactionErrorEvent();
  }
};

export const removeAdminFromOrg = async (
  orgProfileId: string,
  adminToRemove: string,
  onComplete: () => void,
) => {
  dispatch(toggleTxnModalAction(REMOVE_ADMIN_TXNS));

  const updateFn = () => {
    // const oldAdmins = apolloClient.readQuery({
    //   query: FETCH_ALL_ADMINS,
    //   variables: {
    //     profileAddress: orgProfileId,
    //   },
    // });
    // const updatedAdmins = oldAdmins?.fetchAllAdmins?.filter((id: string) => id != adminToRemove);
    // apolloClient.writeQuery({
    //   query: FETCH_ALL_ADMINS,
    //   variables: {
    //     profileAddress: orgProfileId,
    //   },
    //   data: {
    //     fetchAllAdmins: updatedAdmins,
    //   },
    // });
    onComplete();
  };

  const removeSub = subscriptionToEvent(
    REMOVE_ADMIN_FROM_ORG_SUBSCRIPTION,
    onTransactionCompleteEvent,
    onTransactionErrorEvent,
    updateFn,
    {
      orgProfileAddress: orgProfileId,
    },
    'removeAdminFromOrg',
    onTransactionCompleteEvent,
  );
  try {
    const res = await apolloClient.mutate({
      mutation: REMOVE_ADMIN_FROM_ORG,
      variables: {
        adminToRemove: adminToRemove,
        orgProfileAddress: orgProfileId,
      },
      refetchQueries: [
        {
          query: GET_ADMINS_WITH_INFO,
          variables: {
            profileAddress: orgProfileId,
          },
        },
      ],
    });
    if (res) {
      removeSub.unsubscribe();
    }
  } catch (er) {
    console.log(er);
    onTransactionErrorEvent();
    removeSub.unsubscribe();
  }
};

export const createUserInDB = async (user: User | null = null, walletAddress: string = null) => {
  try {
    const res = await apolloClient.mutate({
      mutation: CREATE_USER_IN_DB,
      variables: {
        data: {
          name: user ? user.user_metadata?.name : '',
          email: user ? user.email : '',
          profileImage: user ? user.user_metadata?.avatar_url : '',
        },
        walletAddress: walletAddress,
      },
    });
    return res.data.createUserInDB;
  } catch (error) {
    console.error('Error creating user in DB:', error);
    return null;
  }
};

// read calls
export const fetchAllOrgs = async () => {
  try {
    const res = await apolloClient.query({
      query: FETCH_ALL_ORG_IDS,
    });
    return res?.data?.fetchAllOrgProfiles;
  } catch (er) {
    console.log(er);
  }
};

export const fetchUserProfileData = async (userProfileId: string) => {
  let userProfileData: ProfilePersonalInfo;
  let userOrgs: OrgProfileData[];
  let userGems: number = 0;

  try {
    // Existing profile fetch
    const userProfileResult = await apolloClient.query({
      query: FETCH_USER_PROFILE_DETAILS,
      variables: { id: userProfileId },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });

    // Fetch gems
    const gemsResult = await fetchUserGems(userProfileId);
    if (gemsResult) {
      userGems = gemsResult.gems;
    }

    userProfileData = {
      ...userProfileResult.data.fetchUserPage.data,
      newsLastSeen: userProfileResult.data.fetchUserPage.news_last_seen,
      payment_wallet: userProfileResult.data.fetchUserPage.payment_wallet,
      firstname: userProfileResult.data.fetchUserPage.firstname,
      lastname: userProfileResult.data.fetchUserPage.lastname,
      username: userProfileResult.data.fetchUserPage.username,
      country: userProfileResult.data.fetchUserPage.country,
      gems: userGems, // Add gems to profile data
    };

    // Rest of the existing code...
    const organizationsResult = await apolloClient.query({
      query: GET_ORGANIZATIONS_FOR_USER,
      variables: { profileAddress: userProfileId },
      errorPolicy: 'all',
    });
    userOrgs = organizationsResult?.data?.fetchAllOrganisationsForGivenUser;
    if (organizationsResult.errors) {
      console.error('Organizations Error:', organizationsResult.errors);
    }
  } catch (error) {
    console.error(error);
  }

  return {
    ...userProfileData,
    id: userProfileId,
    organizations: userOrgs ?? [],
    profileId: userProfileId,
    gems: userGems, // Ensure gems are included in the return
  };
};

export const fetchOrgProfileData = async (orgProfileId: string) => {
  try {
    const res = await apolloClient.query({
      query: FETCH_ORG_DETAILS,
      variables: {
        profileAddress: orgProfileId,
      },
    });
    return {
      ...res.data?.fetchOrgPage,
      ...res?.data?.fetchOrgPage?.profile,
      profileId: orgProfileId,
      isOrganization: true,
      orgId: orgProfileId,
      challengeCount: res?.data?.fetchOrgPage?.challenges?.length ?? 0,
    };
  } catch (er) {
    console.log(er);
  }
};

export const isUserProfileRegistered = async (userProfileId: string): Promise<boolean> => {
  try {
    const response = await apolloClient.query<{ isUserProfileRegistered: boolean }>({
      query: IS_USER_PROFILE_REGISTERED,
      variables: { address: userProfileId },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });
    return response.data.isUserProfileRegistered;
  } catch (error) {
    console.log(error);
  }
};

export const fetchuserProfileId = async (
  walletAddress: string = '',
): Promise<string | undefined> => {
  if (!walletAddress) {
    return;
  }

  try {
    const response = await apolloClient.query<{ fetchUserProfileAddress: string | null }>({
      query: FETCH_USER_ADDRESS,
      variables: { address: walletAddress },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    const userProfileId = response.data.fetchUserProfileAddress;
    return userProfileId;
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserOrgs = async (userProfileId: string) => {
  try {
    const res = await apolloClient.query({
      query: FETCH_ALL_ORGS_FOR_USER,
      variables: {
        profileAddress: userProfileId,
      },
    });
    if (res) {
      return res.data.fetchAllOrganisationsForGivenUser;
    }
  } catch (er) {
    console.log(er);
  }
};

export const fetchAllAdmins = async (orgProfileId: string): Promise<string[]> => {
  try {
    const res = await apolloClient.query({
      query: FETCH_ALL_ADMINS,
      variables: {
        profileAddress: orgProfileId,
      },
    });
    if (res.data.fetchAllAdmins) {
      return res.data.fetchAllAdmins;
    }
  } catch (er) {
    console.log(er);
  }
};

export const isWalletInvited = async (userAddress: string, registry: string): Promise<boolean> => {
  const res = await apolloClient.query({
    query: CheckIsWalletInvited,
    fetchPolicy: 'network-only',
    variables: {
      registryAddress: registry,
      walletAddress: userAddress,
    },
  });
  const isInvited: boolean = res.data.checkIfWalletIsInvited;
  return isInvited;
};

export const createOnChainChallenge = async (data: Challenge, navigate: NavigateFunction) => {
  const { profileId: orgProfileId, draftId } = data;
  console.log('data', data);
  const { IPFSChallengeData: challengeData, Web3ChallengeData: challengeDetails } =
    await getAPIMappedChallenge(data, null);
  dispatch(toggleTxnModalAction(CREATE_CHALLENGE_TXNS));

  const updateFunction = (id: string) => {
    try {
      const challengesInCache = apolloClient.readQuery({
        query: GET_ALL_CHALLENGES,
      });

      const orgProfileInCache = apolloClient.readQuery({
        query: FETCH_ORG_DETAILS,
        variables: {
          profileAddress: orgProfileId,
        },
      });

      const newChallenge = {
        __typename: 'ChallengeType',
        org: orgProfileId,
        ...challengeData,
        ...challengeDetails,
        id: id,
        created_on: challengeDetails.createdOn,
        last_modified_on: challengeDetails.createdOn,
        orglogo: '',
        reviewers: challengeDetails.reviewers,
        reward_amount: challengeDetails.rewardAmount,
        reward_amount_usd: challengeData.rewardAmountUSD,
        reward_token: challengeDetails.rewardToken,
      };

      const updatedOrg = {
        ...orgProfileInCache?.fetchOrgPage,
        challenges: [...orgProfileInCache.fetchOrgPage.challenges, newChallenge],
      };

      // Adding challenge to org in cache
      apolloClient.writeQuery({
        query: FETCH_ORG_DETAILS,
        variables: {
          profileAddress: orgProfileId,
        },
        data: {
          fetchOrgPage: updatedOrg,
        },
      });

      const updatedChallengesArray = [...challengesInCache.fetchAllChallengesPage, newChallenge];

      // Adding challenge to all challenges list
      apolloClient.writeQuery({
        query: GET_ALL_CHALLENGES,
        data: {
          fetchAllChallengesPage: updatedChallengesArray,
        },
      });
    } catch (er) {
      console.log(er);
    }

    navigate(CHALLENGE_PATH + '/' + id);
  };
  const sub = subscriptionToEvent(
    CREATE_CHALLENGE_SUBSCRIPTION,
    onTransactionNextEvent,
    onTransactionErrorEvent,
    updateFunction,
    {
      orgProfileAddress: orgProfileId,
    },
    'createChallenge',
    onTransactionCompleteEvent,
  );

  try {
    console.log('draftId', draftId);
    const response = await apolloClient.mutate({
      mutation: CREATE_CHALLENGE_MUTATION,
      variables: {
        orgProfileAddress: orgProfileId,
        challengeData: challengeData,
        challengeDetails: challengeDetails,
        draftId: draftId,
        userId: data.userId || '',
      },
      refetchQueries: [FETCH_ORG_METRICS],
    });

    if (response) {
      console.log('from API', response);
      // return orgProfileId;
    }
  } catch (er) {
    sub.unsubscribe();
    onTransactionErrorEvent();
    console.log(er);
  }
};
export const updateOnChainChallenge = async (
  data: Challenge,
  navigate: NavigateFunction,
  dirtyFields: string[],
  forMarkChallengeComplete?: boolean,
) => {
  const { challengeId, profileId: orgProfileId } = data;
  const { IPFSChallengeData: challengeData, Web3ChallengeData: challengeDetails } =
    await getAPIMappedChallenge(data, dirtyFields);
  const updateFn = (id: string) => {
    const newChallenge = {
      __typename: 'ChallengeType',
      org: orgProfileId,
      ...challengeData,
      ...challengeDetails,
      id: id,
      created_on: challengeDetails.createdOn,
      last_modified_on: challengeDetails.createdOn,
      orglogo: '',
      reviewers: [],
      reward_amount: challengeDetails.rewardAmount,
      reward_amount_usd: challengeDetails.rewardAmount,
      reward_token: challengeDetails.rewardToken,
      total_submissions: '',
    };

    const challengesInCache = apolloClient.readQuery({
      query: GET_ALL_CHALLENGES,
    });
    // const orgProfileInCache = apolloClient.readQuery({
    //   query: FETCH_ORG_DETAILS,
    //   variables: {
    //     profileAddress: orgProfileId,
    //   },
    // });

    const challengePageInCache = apolloClient.readQuery({
      query: GET_CHALLENGE_DETAILS,
      variables: {
        id: challengeId,
      },
    });

    if (challengePageInCache?.fetchChallengePage) {
      //updating chalange detail page in cache
      apolloClient.writeQuery({
        query: GET_CHALLENGE_DETAILS,
        variables: {
          id: challengeId,
        },
        data: {
          fetchChallengePage: newChallenge,
        },
      });
    }

    const tempAllChallenges = [...challengesInCache.fetchAllChallengesPage];
    const filteredTempChallenges = tempAllChallenges?.filter(
      (challenge) => challenge.id != challengeId,
    );

    const updatedAllChallenges = [...filteredTempChallenges, newChallenge];

    // const updatedAllChallengesOfOrg = updatedAllChallenges.filter(
    //   (challenge) => challenge.org === orgProfileId,
    // );
    // const updatedOrg = {
    //   ...orgProfileInCache?.fetchOrgPage,
    //   orglogo: '',
    //   challenges: updatedAllChallengesOfOrg,
    // };

    // Adding challenge to org in cache
    // apolloClient.writeQuery({
    //   query: FETCH_ORG_DETAILS,
    //   variables: {
    //     profileAddress: orgProfileId,
    //   },
    //   data: {
    //     fetchOrgPage: updatedOrg,
    //   },
    // });

    // Adding challenge to all challenges list
    apolloClient.writeQuery({
      query: GET_ALL_CHALLENGES,
      data: {
        fetchAllChallengesPage: updatedAllChallenges,
      },
    });

    navigate(CHALLENGE_PATH + '/' + id);
  };
  if (!forMarkChallengeComplete) {
    dispatch(toggleTxnModalAction(UPDATE_CHALLENGE_TXNS));
  }
  const sub = subscriptionToEvent(
    UPDATE_CHALLENGE_SUBSCRIPTION,
    onTransactionNextEvent,
    onTransactionErrorEvent,
    updateFn,
    {
      orgProfileAddress: orgProfileId,
    },
    'updateChallenge',
    onTransactionCompleteEvent,
  );
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_CHALLENGE_MUTATION,
      variables: {
        challengeId: challengeId,
        orgProfileAddress: orgProfileId,
        challengeData: challengeData,
        challengeDetails: challengeDetails,
      },
      refetchQueries: [
        {
          query: ALL_SUBMISSIONS_FOR_CHALLENGE,
          variables: {
            challengeId: challengeId,
          },
        },
      ],
    });
    if (response) {
      console.log(response);
    }
  } catch (er) {
    console.log(er);
    sub.unsubscribe();
    onTransactionErrorEvent();
  }
};

export const fetchSubmissionDetails = async (submissionId: number | string) => {
  try {
    const res = await apolloClient.query({
      query: FETCH_SUBMISSION_DETAILS,
      variables: {
        submissionId: submissionId,
      },
    });
    return res?.data?.fetchSubmissionDetails;
  } catch (er) {
    console.log(er);
  }
};

export const fetchTokenDetails = async (tokenContractAddress) => {
  try {
    const { data: tokenDetails } = await axios.get(
      'https://api.coingecko.com/api/v3/simple/token_price/ethereum',
      {
        params: {
          contract_addresses: tokenContractAddress.toString(),
          vs_currencies: 'usd',
        },
      },
    );
    return tokenDetails;
  } catch (error) {
    console.error('fetchTokenDetails:', error);
  }
};

export const submitSumbission = async (subData, navigate) => {
  const {
    challengeId,
    details,
    email,
    orgId,
    profileId,
    status,
    submissionTime,
    title,
    rewardToken,
    rewardAmount,
    asset,
  } = subData;

  const updateFn = (id) => {
    try {
      /**Adding few extra fields just so that it gets written in cache, refetch will take care of it */
      const newSubmission = {
        __typename: 'Submission',
        challengeId: challengeId,
        created_on: submissionTime,
        creator: profileId,
        creatorProfilePic: '',
        firstName: '',
        lastName: '',
        paymentWallet: '',
        country: '',
        creatorName: '',
        score: '',
        rank: '',
        did: '',
        data: {
          _typename: 'SubmissionDataOutput',
          assets: [''],
          details: details,
          title: title,
          email: email,
        },
        data_key: '',
        id: id,
        last_modified_on: submissionTime,
        orgId: orgId,
        status: status,
        reviews: [],
      };

      let updatedSubs = [];
      let updatedSubsForUser = [];
      // navigate(CHALLENGE_PATH + '/' + challengeId + '/' + 1);

      const submissionsInCache = apolloClient.readQuery({
        query: ALL_SUBMISSIONS_FOR_CHALLENGE,
        variables: {
          challengeId: challengeId,
        },
      });

      const submissionsInCacheForUser = apolloClient.readQuery({
        query: FETCH_ALL_SUBMISSIONS_FOR_USER,
        variables: {
          profileAddress: profileId,
        },
      });

      updatedSubs = [
        ...(submissionsInCache?.fetchAllSubmissionsForGivenChallenge ?? []),
        newSubmission,
      ];
      updatedSubsForUser = [
        ...(submissionsInCacheForUser?.fetchAllSubmissionsForGivenUser ?? []),
        newSubmission,
      ];
      apolloClient.writeQuery({
        query: ALL_SUBMISSIONS_FOR_CHALLENGE,
        variables: {
          challengeId: challengeId,
        },
        data: {
          fetchAllSubmissionsForGivenChallenge: updatedSubs,
        },
      });

      apolloClient.writeQuery({
        query: FETCH_ALL_SUBMISSIONS_FOR_USER,
        variables: {
          profileAddress: profileId,
        },
        data: {
          fetchAllSubmissionsForGivenUser: updatedSubsForUser,
        },
      });
    } catch (er) {
      console.log(er);
    }
    navigate(CHALLENGE_PATH + '/' + challengeId + '/' + 0);
  };

  // updateFn('50');

  const subtoEvent = subscriptionToEvent(
    CREATE_SUBMISSION_SUBSCRIPTION,
    onTransactionNextEvent,
    onTransactionErrorEvent,
    updateFn,
    {
      submitter: profileId,
    },
    'createSubmissionSub',
    onTransactionCompleteEvent,
  );

  try {
    const res = await apolloClient.mutate({
      mutation: CREATE_SUBMISSION_MUTATION,
      variables: {
        challengeId: challengeId,
        details: details,
        title: title,
        submittedOn: submissionTime,
        submitter: profileId,
        status: status,
        userProfileAddress: profileId,
        orgId: orgId,
        assets: [''],
        rewardToken: rewardToken,
        rewardAmount: rewardAmount,
        email: email,
        asset: asset,
      },
      refetchQueries: [
        {
          query: FETCH_ALL_SUBMISSIONS_FOR_USER,
          variables: {
            profileAddress: profileId,
          },
        },
        {
          query: ALL_SUBMISSIONS_FOR_CHALLENGE,
          variables: {
            challengeId: challengeId,
          },
        },
      ],
    });
    if (res) {
      subtoEvent.unsubscribe();
      onTransactionCompleteEvent('Completed');
    }
  } catch (error) {
    onTransactionErrorEvent();
    subtoEvent.unsubscribe();
    throw new Error(error.message);
  }
};

export const submitTempSumbission = async (subData) => {
  const {
    challengeId,
    details,
    email,
    orgId,
    profileId,
    status,
    submissionTime,
    title,
    rewardToken,
    rewardAmount,
    asset,
  } = subData;

  try {
    const res = await apolloClient.mutate({
      mutation: CREATE_TEMP_SUBMISSION_MUTATION,
      variables: {
        challengeId: challengeId,
        details: details,
        title: title,
        submittedOn: submissionTime,
        submitter: profileId,
        status: status,
        userProfileAddress: profileId,
        orgId: orgId,
        assets: [''],
        rewardToken: rewardToken,
        rewardAmount: rewardAmount,
        email: email,
        asset: asset,
      },
      refetchQueries: [
        {
          query: ALL_SUBMISSIONS_FOR_CHALLENGE,
          variables: {
            challengeId: challengeId,
          },
        },
      ],
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserDetails = async (profileId: string) => {
  try {
    const res = await apolloClient.query({
      query: FETCH_USER_PROFILE_DATA,
      variables: { profileAddress: profileId },
      errorPolicy: 'all',
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addAdminToUser = async (
  address: string,
  adminToAdd: string,
  userProfileAddress: string,
  label: string,
  onComplete: () => void,
) => {
  //USING GQL
  dispatch(toggleTxnModalAction(ADD_ADMIN_TO_USER_TXNS));

  const updateFunction = () => {
    try {
      apolloClient.writeQuery({
        query: FETCH_USER_ADDRESS,
        variables: {
          address: adminToAdd,
        },
        data: {
          fetchUserProfileAddress: userProfileAddress,
        },
      });
    } catch (er) {
      console.log(er);
    }

    onComplete();
  };

  try {
    subscriptionToEvent(
      ADD_ADMIN_TO_USER_PROFILE_SUB,
      onTransactionNextEvent,
      onTransactionErrorEvent,
      updateFunction,
      {
        userProfileAddress: userProfileAddress,
      },
      'addAdminToUserProfileSub',
      onTransactionCompleteEvent,
    );

    const res = await apolloClient.mutate({
      mutation: ADD_ADMIN_TO_USER,
      variables: {
        address,
        adminToAdd,
        userProfileAddress,
        label,
      },
      refetchQueries: [
        {
          query: FETCH_ALL_ADMINS_FOR_USER,
          variables: {
            profileAddress: userProfileAddress,
          },
        },
      ],
    });
    res && console.log(res);
  } catch (er) {
    console.log(er);
    onTransactionErrorEvent();
  }
};

export const removeAdminOfUser = async (
  address: string,
  adminToRemove: string,
  userProfileAddress: string,
  onComplete: () => void,
) => {
  //USING GQL
  dispatch(toggleTxnModalAction(REMOVE_ADMIN_FROM_USER_TXNS));

  const updateFunction = () => {
    try {
      apolloClient.writeQuery({
        query: FETCH_USER_ADDRESS,
        variables: {
          address: adminToRemove,
        },
        data: {
          fetchUserProfileAddress: null,
        },
      });
    } catch (er) {
      console.log(er);
    }

    onComplete();
  };

  try {
    subscriptionToEvent(
      REMOVE_ADMIN_OF_USER_PROFILE_SUB,
      onTransactionNextEvent,
      onTransactionErrorEvent,
      updateFunction,
      {
        userProfileAddress: userProfileAddress,
      },
      'removeAdminOfUserProfileSub',
      onTransactionCompleteEvent,
    );

    const res = await apolloClient.mutate({
      mutation: REMOVE_ADMIN_OF_USER,
      variables: {
        address,
        adminToRemove,
        userProfileAddress,
      },
      refetchQueries: [
        {
          query: FETCH_ALL_ADMINS_FOR_USER,
          variables: {
            profileAddress: userProfileAddress,
          },
        },
      ],
    });
    res && console.log(res);
  } catch (er) {
    console.log(er);
    onTransactionErrorEvent();
  }
};

export const createChallengeDraft = async (data: Challenge, navigate: NavigateFunction) => {
  const { profileId: orgProfileId } = data;
  const { IPFSChallengeData: challengeData, Web3ChallengeData: challengeDetails } =
    await getAPIMappedChallenge(data, null);
  const updateFunction = (id: string) => {
    try {
      const challengesInCache = apolloClient.readQuery({
        query: GET_ALL_CHALLENGES,
      });

      const orgProfileInCache = apolloClient.readQuery({
        query: FETCH_ORG_DETAILS,
        variables: {
          profileAddress: orgProfileId,
        },
      });

      const newChallenge = {
        __typename: 'ChallengeType',
        org: orgProfileId,
        ...challengeData,
        ...challengeDetails,
        id: id,
        created_on: challengeDetails.createdOn,
        last_modified_on: challengeDetails.createdOn,
        orglogo: '',
        reviewers: challengeDetails.reviewers,
        reward_amount: challengeDetails.rewardAmount,
        reward_amount_usd: challengeDetails.rewardAmount,
        reward_token: challengeDetails.rewardToken,
      };

      const updatedOrg = {
        ...orgProfileInCache?.fetchOrgPage,
        challenges: [...orgProfileInCache.fetchOrgPage.challenges, newChallenge],
      };

      // Adding challenge to org in cache
      apolloClient.writeQuery({
        query: FETCH_ORG_DETAILS,
        variables: {
          profileAddress: orgProfileId,
        },
        data: {
          fetchOrgPage: updatedOrg,
        },
      });

      const updatedChallengesArray = [...challengesInCache.fetchAllChallengesPage, newChallenge];

      // Adding challenge to all challenges list
      apolloClient.writeQuery({
        query: GET_ALL_CHALLENGES,
        data: {
          fetchAllChallengesPage: updatedChallengesArray,
        },
      });
    } catch (er) {
      console.log(er);
    }

    navigate(CHALLENGE_PATH + '/' + id);
  };
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_DRAFT_CHALLENGE_MUTATION,
      variables: {
        orgProfileAddress: orgProfileId,
        challengeData: challengeData,
        challengeDetails: challengeDetails,
      },
    });
    updateFunction(response?.data?.createDraft);
    if (response) {
      console.log('from API', response);
      // return orgProfileId;
    }
  } catch (er) {
    console.log(er);
  }
};

export const updateChallengeDraft = async (
  data: Challenge,
  navigate: NavigateFunction,
  dirtyFields: string[],
) => {
  const { challengeId, profileId: orgProfileId } = data;
  const { IPFSChallengeData: challengeData, Web3ChallengeData: challengeDetails } =
    await getAPIMappedChallenge(data, dirtyFields);
  const updateFn = (id: string) => {
    const newChallenge = {
      __typename: 'ChallengeType',
      org: orgProfileId,
      ...challengeData,
      ...challengeDetails,
      id: id,
      created_on: challengeDetails.createdOn,
      last_modified_on: challengeDetails.createdOn,
      orglogo: '',
      reviewers: [],
      reward_amount: challengeDetails.rewardAmount,
      reward_amount_usd: challengeDetails.rewardAmount,
      reward_token: challengeDetails.rewardToken,
    };

    const challengesInCache = apolloClient.readQuery({
      query: GET_ALL_CHALLENGES,
    });
    const orgProfileInCache = apolloClient.readQuery({
      query: FETCH_ORG_DETAILS,
      variables: {
        profileAddress: orgProfileId,
      },
    });

    const challengePageInCache = apolloClient.readQuery({
      query: GET_CHALLENGE_DETAILS,
      variables: {
        id: challengeId,
      },
    });

    if (challengePageInCache?.fetchChallengePage) {
      //updating chalange detail page in cache
      apolloClient.writeQuery({
        query: GET_CHALLENGE_DETAILS,
        variables: {
          id: challengeId,
        },
        data: {
          fetchChallengePage: newChallenge,
        },
      });
    }

    const tempAllChallenges = [...challengesInCache.fetchAllChallengesPage];
    const filteredTempChallenges = tempAllChallenges?.filter(
      (challenge) => challenge.id != challengeId,
    );

    const updatedAllChallenges = [...filteredTempChallenges, newChallenge];

    const updatedAllChallengesOfOrg = updatedAllChallenges.filter(
      (challenge) => challenge.org === orgProfileId,
    );
    const updatedOrg = {
      ...orgProfileInCache?.fetchOrgPage,
      challenges: updatedAllChallengesOfOrg,
    };

    // Adding challenge to org in cache
    apolloClient.writeQuery({
      query: FETCH_ORG_DETAILS,
      variables: {
        profileAddress: orgProfileId,
      },
      data: {
        fetchOrgPage: updatedOrg,
      },
    });

    // Adding challenge to all challenges list
    apolloClient.writeQuery({
      query: GET_ALL_CHALLENGES,
      data: {
        fetchAllChallengesPage: updatedAllChallenges,
      },
    });

    navigate(CHALLENGE_PATH + '/' + id);
  };
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_DRAFT_MUTATION,
      variables: {
        challengeId: challengeId,
        orgProfileAddress: orgProfileId,
        challengeData: challengeData,
        challengeDetails: challengeDetails,
      },
    });
    if (response) {
      updateFn(response?.data?.updateDraft);
      console.log(response);
    }
  } catch (er) {
    console.log(er);
  }
};

/**To get meta data of did */

export const didMetaData = async (did: string) => {
  if (!did) return;
  try {
    const res = await apolloClient.query({
      query: FETCH_DID_METADATA,
      variables: { did: did },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    return res?.data?.fetchDidMetaData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

/**To get downloadable link using DID */

export const getDownloadableLink = async (did: string) => {
  dispatch(toggleTxnModalAction(GET_DOWNLOAD_URL));

  const subtoEvent = subscriptionToEvent(
    GET_DOWNLOAD_URL_SUB,
    onTransactionNextEvent,
    onTransactionErrorEvent,
    () => {},
    {
      did: did,
    },
    'getDownloadableURL',
    onTransactionCompleteEvent,
  );
  try {
    const res = await apolloClient.query({
      query: GET_DOWNLOADABLE_URL,
      variables: { did: did },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    subtoEvent.unsubscribe();
    return res?.data;
  } catch (error) {
    console.log(error);
    onTransactionErrorEvent();
    subtoEvent.unsubscribe();
    return error;
  }
};
export const getSocialUserWithJWTtoken = async (jwtToken: string) => {
  try {
    const res = await apolloClient.query({
      query: GET_SOCIAL_USER_WITH_JWTTOKEN,
      variables: { jwtToken: jwtToken },
      fetchPolicy: 'network-only',
    });
    return res.data.getSocialUserWithJWTtoken;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};
export const fetchUserFromDB = async (email: string = '', walletAddress: string = '') => {
  try {
    const res = await apolloClient.query({
      query: FETCH_USER_FROM_DB,
      variables: { email: email, walletAddress: walletAddress },
      fetchPolicy: 'network-only',
    });
    return res.data.fetchUserFromDB;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};
export const verifySignatureAndReturnAddress = async (
  message: string,
  signature: string,
  walletAddress: string,
  connectorName: string,
  email: string,
) => {
  try {
    const res = await apolloClient.mutate({
      mutation: VERIFY_SIGNATURE_AND_RETURN_ADDRESS,
      variables: {
        message: message,
        signature: signature,
        walletAddress: walletAddress,
        connectorName: connectorName,
        email: email,
      },
    });
    return res.data.verifySignatureAndReturnAddress;
  } catch (error) {
    return null;
  }
};

// export const storePasskeyInDB = async (id: string, passkey: PasskeyArgType) => {
//   try {
//     await apolloClient.mutate({
//       mutation: STORE_PASSKEY_IN_DB,
//       variables: {
//         id: id,
//         rawId: passkey.rawId,
//         x: passkey.coordinates.x,
//         y: passkey.coordinates.y,
//       },
//     });
//   } catch (error) {
//     console.log('error', error);
//   }
// };

export const fetchPasskeysFromDB = async (id: string) => {
  try {
    const res = await apolloClient.query({
      query: FETCH_PASSKEYS_FROM_DB,
      variables: { id: id },
      fetchPolicy: 'network-only',
    });
    // console.log('res', res.data);
    return res.data.fetchPasskeysFromDB;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

export const isPasskeyCreated = async (id: string) => {
  const passkey = await fetchPasskeysFromDB(id);
  if (passkey) {
    console.log('true');
    return true;
  }
  console.log('false');
  return false;
};

export const fetchUserGems = async (userId: string) => {
  try {
    const res = await apolloClient.query({
      query: GET_USER_GEMS,
      variables: { userId },
      fetchPolicy: 'network-only', // To ensure we get fresh data
    });
    return res.data.getUserGems;
  } catch (error) {
    console.error('Error fetching user gems:', error);
    return null;
  }
};
