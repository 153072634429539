import { useMutation } from '@apollo/client';
import { TRACK_EVENT } from '../../graphql/Analytics/mutations';
import { SIGNIN_ACTIVITY } from '../analytics';
import {
  getUtmParams,
  getLocationInfo,
  getUserInfo,
  getDeviceInfo,
  getScreenInfo,
} from '../analytics';

interface SignInTrackingProps {
  provider: string;
  status: 'success' | 'failed';
  email?: string;
  userId?: string;
}

// Keep track of ongoing sign-in attempts to prevent duplicate tracking
const ongoingSignIns = new Set<string>();

export const useSignInTracking = () => {
  const [trackEvent] = useMutation(TRACK_EVENT);

  const trackSignIn = async ({
    provider,
    status,
    email = '',
    userId = '',
  }: SignInTrackingProps) => {
    try {
      const trackingKey = `${provider}-${status}-${userId}`;

      // Skip if we're already tracking this sign-in attempt
      if (ongoingSignIns.has(trackingKey)) {
        return;
      }

      // Add to ongoing tracking set
      ongoingSignIns.add(trackingKey);

      // console.log('Starting sign-in tracking for provider:', provider);
      // Get all tracking properties
      const utmParams = getUtmParams();
      const deviceInfo = getDeviceInfo();
      const screenInfo = getScreenInfo();
      const userInfo = getUserInfo();
      const locationInfo = await getLocationInfo();

      // console.log('Collected tracking data:', {
      //   utmParams,
      //   deviceInfo,
      //   screenInfo,
      //   userInfo,
      //   locationInfo,
      // });

      await trackEvent({
        variables: {
          event: SIGNIN_ACTIVITY,
          properties: {
            provider,
            status,
            email,
            userId,
            timestamp: new Date().toISOString(),
            ...utmParams,
            ...deviceInfo,
            ...screenInfo,
            ...userInfo,
            ...locationInfo,
          },
        },
      });
      // console.log('Successfully tracked sign-in event');
    } catch (error) {
      // console.error('Failed to track sign-in event:', error);
    } finally {
      // Remove from ongoing tracking set after completion
      const trackingKey = `${provider}-${status}-${userId}`;
      ongoingSignIns.delete(trackingKey);
    }
  };
  return { trackSignIn };
};
