import { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import QuestSpinner from './Components/QuestSpinner';
import { Provider } from 'react-redux';
import store from './Store';
import { WagmiProvider } from 'wagmi';
import MainRouter from './Routes/MainRouter';
import { wagmiAdapter } from './Utils/Helpers/WallectConfigHelper';
import NetworkSwitchListener from './Components/NetworkSwitchListener';
import TxnModal from './Components/Modals/TxnModal';
import ErrorBoundary from './Components/ErrorBoundary';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  useMutation,
} from '@apollo/client';
import { primaryMetaTags, FBmetaOgTags, TwitterOgTags } from './Utils/Constants';
import MetaHemletTags from './Components/MetaHemletTags';
// import CheckLatestFeatures from './Components/CheckLatestFeatures';
// import { RootState } from './@types';
import LevelUpModal from './Components/LevelUpModal';
import { useNotifications } from './Utils/Hooks/useNotifications';
import { TRACK_EVENT } from './graphql/Analytics/mutations';
import {
  getUtmParams,
  getDeviceInfo,
  getScreenInfo,
  getUserInfo,
  getLocationInfo,
} from './Utils/analytics';
import CookieModal from './Components/Modals/CookieModal';
import { getCookie } from './Utils/Helpers/CommonHelpers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API,
});

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        keyFields: false,
        merge: false,
      },
    },
    dataIdFromObject: () => null,
  }),
});

const AppContent = () => {
  // const currentProfile = useSelector((state: RootState) => state.profile.defaultProfile);
  const [showLevelUpModal, setShowLevelUpModal] = useState(false);
  const [levelUpPayload, setLevelUpPayload] = useState<{ newLevel: number } | null>(null);
  const [currentNotificationId, setCurrentNotificationId] = useState<string | null>(null);
  const [showConcentPopup, setShowConcentPopup] = useState(false);
  const location = useLocation();

  const { unreadNotifications, markNotificationsAsRead, refetchNotifications } = useNotifications();
  const [trackEvent] = useMutation(TRACK_EVENT);

  // Check for level up notifications
  useEffect(() => {
    const levelUpNotification = unreadNotifications.find(
      (notification) => notification.type === 'LEVEL_UP',
    );

    if (levelUpNotification) {
      setLevelUpPayload(levelUpNotification.payload);
      setCurrentNotificationId(levelUpNotification.id);
      setShowLevelUpModal(true);
    }
  }, [unreadNotifications]);

  useEffect(() => {
    const preferences = getCookie('preferences');
    if (!preferences || preferences.length === 0) {
      setShowConcentPopup(true);
    }
  }, []);
  // Handle modal close
  const handleModalClose = async () => {
    if (currentNotificationId) {
      await markNotificationsAsRead([currentNotificationId]);
    }
    setShowLevelUpModal(false);
    setCurrentNotificationId(null);
    setLevelUpPayload(null);
  };
  // Refetch on route change
  useEffect(() => {
    refetchNotifications();
  }, [location.pathname, refetchNotifications]);

  useEffect(() => {
    const trackPageLoad = async () => {
      const startTime = Date.now();
      const utmParams = getUtmParams();
      const deviceInfo = getDeviceInfo();
      const screenInfo = getScreenInfo();
      const userInfo = getUserInfo();
      const locationInfo = await getLocationInfo();

      try {
        await trackEvent({
          variables: {
            event: 'PAGE_LOAD',
            properties: {
              path: window.location.pathname,
              timestamp: new Date().toISOString(),
              sessionDuration: (Date.now() - startTime) / 1000,
              ...utmParams,
              ...deviceInfo,
              ...screenInfo,
              ...userInfo,
              ...locationInfo,
            },
          },
        });
      } catch (error) {
        console.error('Error tracking page load:', error);
      }
    };

    trackPageLoad();
  }, [trackEvent]);

  return (
    <>
      <MetaHemletTags
        pageTitle={primaryMetaTags.title}
        primaryDescription={primaryMetaTags.description}
        primaryImage={primaryMetaTags.image}
        fbType={FBmetaOgTags.type}
        fbUrl={FBmetaOgTags.url}
        fbTitle={FBmetaOgTags.title}
        fbDescription={FBmetaOgTags.description}
        fbImage={FBmetaOgTags.image}
        twitterCard={TwitterOgTags.card}
        twitterURL={TwitterOgTags.url}
        twitterDescription={TwitterOgTags.description}
        twitterImage={TwitterOgTags.image}
        twitterTitle={TwitterOgTags.title}
      />
      <Suspense
        fallback={
          <div className='text-center'>
            <QuestSpinner size='lg' />
          </div>
        }
      >
        <ErrorBoundary>
          <MainRouter />
          <NetworkSwitchListener />
        </ErrorBoundary>
      </Suspense>
      <TxnModal />
      {/* <CheckLatestFeatures /> */}

      {showLevelUpModal && levelUpPayload && (
        <LevelUpModal payload={levelUpPayload} onClose={handleModalClose} />
      )}

      <CookieModal showConcentPopup={showConcentPopup} setShowConcentPopup={setShowConcentPopup} />
    </>
  );
};

// Create a client
const queryClient = new QueryClient();

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={wagmiAdapter.wagmiConfig}>
            <Router>
              <AppContent />
            </Router>
          </WagmiProvider>
        </QueryClientProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
