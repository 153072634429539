import { Sidebar } from 'flowbite-react';
import { NavLink } from 'react-router-dom';
import { SideBarItem, SubMenu, renderSidebarListInterface } from '../../@types';
import { Tooltip } from 'flowbite-react';

export const RenderSidebarList = ({
  renderSidebar,
  setSidebarOpen,
  isCollapsed,
}: renderSidebarListInterface) => {
  return (
    <div>
      {renderSidebar.map((item: SideBarItem, index) => (
        <div key={index} className='group/item [&>div]:w-full'>
          {!item.subPages?.length ? (
            <Tooltip
              content={item.name}
              placement='right'
              className={` !w-auto
              ${
                isCollapsed
                  ? 'transition-colors duration-300 '
                  : 'group-hover/item:text-lightTextColor transition-colors duration-300 hidden'
              }
            `}
            >
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  `flex ${
                    isActive
                      ? 'bg-primary-50 dark:bg-darkSidebarItem'
                      : 'hover:bg-primary-50 dark:hover:bg-darkSidebarItem'
                  } mt-1 rounded-[22px] transition-colors duration-300`
                }
                onClick={() => setSidebarOpen(false)}
              >
                {({ isActive }) => (
                  <Sidebar.Item
                    as='div'
                    icon={item.icon}
                    className={`w-full ${isCollapsed ? 'py-3 pl-3 pr-0 !justify-center' : 'py-3'} ${
                      isActive
                        ? 'text-lightTextColor dark:text-primary-700 [&_svg]:!text-lightTextColor [&_svg]:dark:!text-primary-700 [&_svg]:transition-colors [&_svg]:duration-300'
                        : ''
                    }`}
                  >
                    <p
                      className={`text-sm group-hover/item:text-lightTextColor dark:group-hover/item:text-primary-700 transition-colors duration-300 ${isActive ? 'text-lightTextColor dark:text-primary-700' : 'text-inherit'} ${isCollapsed ? 'hidden' : ''}`}
                    >
                      {item.name}
                    </p>
                  </Sidebar.Item>
                )}
              </NavLink>
            </Tooltip>
          ) : (
            !isCollapsed && (
              <Sidebar.Collapse className='!text-sm' label={item.name} icon={item.icon}>
                {item.subPages.map((subItem: SubMenu, subIndex) => (
                  <NavLink
                    key={subIndex}
                    to={subItem.subPath}
                    className={({ isActive }) =>
                      `flex ${
                        isActive
                          ? 'bg-primary-50 dark:bg-darkSidebarItem'
                          : 'hover:bg-primary-50 dark:hover:bg-darkSidebarItem'
                      } mt-1 rounded-[22px] transition-colors duration-300`
                    }
                    onClick={() => setSidebarOpen(false)}
                  >
                    <Sidebar.Item
                      as='div'
                      icon={subItem.subIcon}
                      className='hover:bg-primary-50 dark:hover:bg-darkSidebarItem transition-colors duration-300'
                    >
                      <div className='flex items-center text-sm'>{subItem.subName}</div>
                    </Sidebar.Item>
                  </NavLink>
                ))}
              </Sidebar.Collapse>
            )
          )}
        </div>
      ))}
    </div>
  );
};
