import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stepConfig, STEP_COMPLETED, STEP_PENDING } from '../../Utils/Constants';

const initialTxn = {
  currentStepsTitle: '',
  currentSteps: [],
  currentStepId: 1,
};

const utilsInitialState = {
  showTxnModal: false,
  currentStepsTitle: '',
  currentSteps: [],
  currentStepId: 1,
  successMessage: '',
  errorMessage: '',
  // will be used after gradual improvements.
  // This will be more useful with the localstorage system.
  currentTxn: initialTxn,
  oldTxns: Object(),
  // This key will be removed
  tempStatus: '',
  allChallengesLoading: false,
  orgChallengeLoading: false,
  isGuest: true,
  isOrgSelected: false,
  initialLoading: false,
  sidebarSyncDetails: { name: '', image: '' },
  hasJoinedCompetition: false,
};

// Localstorage persistense - Not tested well.
let initialState = utilsInitialState;
const presistedState = localStorage.getItem('persistedstate');
// console.log(presistedState)
if (presistedState) {
  initialState = JSON.parse(presistedState)?.utils ?? utilsInitialState;
}

const UtilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    utilsReset: () => utilsInitialState,
    toggleTxnModal: (state, { payload }) => {
      state.showTxnModal = !state.showTxnModal;
      // This definition will be removed.
      state.tempStatus = payload;
    },
    startTxnSteps: (state, { payload }) => {
      // The first step triggered and the redux set to accept the next event properly.
      state.currentStepId = 1;
      state.currentStepsTitle = payload.title;
      state.currentSteps = JSON.parse(
        JSON.stringify(stepConfig[payload.title as keyof typeof stepConfig]),
      );
      state.currentSteps[0].status = STEP_PENDING;
    },
    nextTxnStep: (state, { payload }) => {
      // next called with the correct payload means the current step was success.
      const nextTxnStep = state.currentStepId + 1;
      // Current step set as completed.
      const currentIndex = state.currentSteps.findIndex(
        (step) => step.stepId === state.currentStepId,
      );
      state.currentSteps[currentIndex].status = STEP_COMPLETED;
      // Next step will be set to pending to show the loader.
      const nextIndex = state.currentSteps.findIndex((step) => step.stepId === nextTxnStep);
      if (nextIndex !== -1) {
        state.currentSteps[nextIndex].status = STEP_PENDING;
        state.currentStepId = nextTxnStep;
      }
      console.log('payload in nextTxn', payload);
    },
    // The below actions will be updated based on the payload.
    completeTxnSteps: (state, { payload }) => {
      state.tempStatus = 'success';
      state.currentStepId = 1;
      state.currentStepsTitle = '';
      state.successMessage = 'Transaction successfully completed.';
      console.log('payload in completeTxn', payload);
    },
    errorTxnSteps: (state, { payload }) => {
      state.tempStatus = 'failure';
      state.currentStepId = 1;
      state.currentStepsTitle = '';
      state.errorMessage = 'Transaction failed. Please try again.';
      console.log('payload in errorTxn', payload);
    },
    updateAllChallengeLoading: (state, { payload }) => {
      state.allChallengesLoading = payload;
    },
    updateOrgChallengeLoading: (state, { payload }) => {
      state.orgChallengeLoading = payload;
    },
    updateGuestState: (state, { payload }) => {
      state.isGuest = payload;
    },
    updateSelectedProfile: (
      state,
      { payload: { isOrg } }: { payload: { profileId: string; isOrg: boolean } },
    ) => {
      state.isOrgSelected = isOrg;
    },
    updateOrgSelected: (state, { payload }) => {
      state.isOrgSelected = payload;
    },
    updateInitialLoading: (state, { payload }) => {
      state.initialLoading = payload;
    },
    updateSidebarSyncDetails: (state, { payload }) => {
      state.sidebarSyncDetails = { ...state.sidebarSyncDetails, ...payload };
    },
    resetSidebarSyncDetails: (state) => {
      state.sidebarSyncDetails = { name: '', image: '' };
    },
    setJoinedCompetition: (state, action: PayloadAction<boolean>) => {
      state.hasJoinedCompetition = action.payload;
    },
  },
});

export default UtilsSlice.reducer;
export const {
  toggleTxnModal,
  utilsReset,
  startTxnSteps,
  nextTxnStep,
  completeTxnSteps,
  errorTxnSteps,
  updateAllChallengeLoading,
  updateOrgChallengeLoading,
  updateGuestState,
  updateSelectedProfile,
  updateOrgSelected,
  updateInitialLoading,
  updateSidebarSyncDetails,
  resetSidebarSyncDetails,
  setJoinedCompetition,
} = UtilsSlice.actions;
