import { Avatar } from 'flowbite-react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonAvatarProps, RootState } from '../@types';
import { useProfile } from '../Utils/Hooks';
import {
  USER_PROFILE_DETAILS_PATH,
  GUEST_ORG_DETAILS_PATH,
  ORG_PROFILE_DETAILS_PATH,
  USER_ORG_DETAILS_PATH,
  SHARED_PATH,
} from '../Utils/Constants';

const CommonAvatar = ({
  img,
  size = 'md',
  wrapperClasses = '',
  profileId,
  username,
  navigation = true,
}: CommonAvatarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedProfile, isProfileAvailable } = useProfile(profileId);
  const currentProfile = useSelector((state: RootState) => state.profile.currentProfile);
  const isOrgSelected = useSelector((state: RootState) => state.utils.isOrgSelected);
  const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  // const [initials, setInitials] = useState('L');

  // console.log('Selected Profile:', selectedProfile);
  // console.log('Current Profile:', currentProfile);
  // console.log('Org name:', selectedProfile?.name);
  // console.log('user name:', selectedProfile?.firstname);
  // useEffect(() => {
  //   if (selectedProfile?.name) {
  //     setInitials(selectedProfile.name.charAt(0).toUpperCase());
  //   }
  //   if (selectedProfile?.firstname) {
  //     setInitials(selectedProfile.firstname.charAt(0).toUpperCase());
  //   }
  // }, [selectedProfile]);

  const profileIcon = useMemo(() => {
    let imageString = img ?? '';
    if (isProfileAvailable) imageString = selectedProfile.profileImage ?? img;
    if (
      typeof imageString === 'string' &&
      (imageString.startsWith('http') || imageString.startsWith('data:image'))
    )
      return imageString;
    const imgData = localStorage.getItem(typeof imageString === 'string' ? imageString : '') ?? '';
    return imgData || '';
  }, [selectedProfile, img, isProfileAvailable]);

  const handleNavigation = () => {
    if (navigation && profileId && !location.pathname.includes(SHARED_PATH)) {
      let path = USER_PROFILE_DETAILS_PATH;
      if (isGuest) path = GUEST_ORG_DETAILS_PATH;
      if (selectedProfile.isOrganization) {
        if (isOrgSelected) {
          if (selectedProfile.profileId === currentProfile.profileId) {
            path = ORG_PROFILE_DETAILS_PATH;
          } else {
            path = ORG_PROFILE_DETAILS_PATH;
          }
        } else {
          path = USER_ORG_DETAILS_PATH;
        }
      }
      if (username) {
        navigate([path, username].join('/'));
      } else {
        navigate([path, profileId].join('/'));
      }
    }
  };

  const getAvatarSize = () => {
    switch (size) {
      case 'xs':
        return 'w-6 h-6';
      case 'sm':
        return 'w-8 h-8';
      case 'md':
        return 'w-10 h-10';
      case 'lg':
        return 'w-20 h-20';
      case 'xl':
        return 'w-36 h-36';
      default:
        return 'w-10 h-10';
    }
  };

  const getInitialsSize = () => {
    switch (size) {
      case 'xs':
        return 'text-xs';
      case 'sm':
        return 'text-sm';
      case 'md':
        return 'text-base';
      case 'lg':
        return 'text-xl';
      case 'xl':
        return 'text-2xl';
      default:
        return 'text-base';
    }
  };

  return (
    <div
      className={`w-fit h-fit mx-auto min-w-max self-center ${
        navigation && !location.pathname.includes(SHARED_PATH) ? 'cursor-pointer' : ''
      } ${wrapperClasses}`}
      onClick={handleNavigation}
    >
      <Avatar
        img={profileIcon}
        rounded={true}
        size={size}
        className={`${getAvatarSize()} overflow-hidden`}
        theme={{
          root: {
            img: {
              base: 'rounded-full object-cover object-center w-full h-full',
              off: 'relative overflow-hidden bg-gray-100 dark:bg-gray-600',
            },
            initials: {
              base: `flex items-center justify-center font-medium text-gray-600 dark:text-gray-300 ${getInitialsSize()}`,
            },
          },
        }}
      />
    </div>
  );
};

export default CommonAvatar;
