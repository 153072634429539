import { Button, Modal, Spinner } from 'flowbite-react';
import { TiWarning } from 'react-icons/ti';
import { TbArrowBackUp } from 'react-icons/tb';
import { useAccount, useChainId, useSwitchChain } from 'wagmi';
// import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import { useEffect, useState } from 'react';
import { HERO_PATH } from '../Utils/Constants';
import { supportedChains } from '../Utils/Helpers';

// This page will restrict the user from switching network after connecting the wallet.
const NetworkSwitchListener = () => {
  const [showModal, setShowModal] = useState(false);
  const { isConnected } = useAccount();
  // const { chain: currentChain } = useNetwork();
  // const { isLoading, switchNetwork } = useSwitchNetwork();
  const chainId = useChainId();
  const { isPending, switchChain } = useSwitchChain();
  const chainValues = process.env.REACT_APP_CHAIN_ID || 137;

  // Convert Mumbai chainId to Sepolia since Mumbai isn't supported by WalletConnect
  const effectiveChainValues = Number(chainValues) === 80001 ? 11155111 : Number(chainValues);

  useEffect(() => {
    if (isConnected) {
      // console.log('Current chain configuration:');
      // console.log('Environment chain ID:', chainValues);
      // console.log('Effective chain value:', effectiveChainValues);
      // console.log('Current chain ID:', chainId);
      // console.log('Supported chains:', supportedChains);

      // Handle chain mismatch
      if (chainId !== effectiveChainValues) {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    }
  }, [chainId, isConnected]);

  const handleBackClick = () => {
    // switchChain?.(Number(supportedChains[0].id));
    switchChain({ chainId: Number(supportedChains[0].id) });
  };

  return (
    <Modal show={showModal && location.pathname !== HERO_PATH} size='md'>
      <Modal.Body className='text-center'>
        <div className='flex justify-center'>
          <TiWarning className='text-amber-400 text-6xl self-center' />
        </div>
        <h3 className='mb-1 text-center text-lg font-semibold text-gray-900 dark:text-white'>
          Network not supported
        </h3>
        <div className='text-gray-900 dark:text-gray-300'>
          OOPS! This site only supports {supportedChains[0].name}. Please switch back to{' '}
          {supportedChains[0].name} from your wallet.
        </div>
        <Button className='mx-auto mt-4 w-fit' onClick={handleBackClick} disabled={isPending}>
          {isPending && (
            <div className='mr-3'>
              <Spinner size='sm' light={true} />
            </div>
          )}
          {!isPending && <TbArrowBackUp className='text-white text-lg md:text-2xl' />}
          <span className='md:text-lg ml-2'>Switch back to {supportedChains[0].name}</span>
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default NetworkSwitchListener;
