import { getNow } from '../Helpers/DateHelpers';

export const NEW_ORGANISATION_OBJ = {
  bio: '',
  name: '',
  website: '',
  twitter: '',
  discord: '',
  telegram: '',
  email: '',
  linkedin: '',
  github: '',
};
export const NEW_USER_PROFILE_OBJ = {
  bio: '',
  name: '',
  website: '',
  twitter: '',
  discord: '',
  telegram: '',
  email: '',
  linkedin: '',
  kaggle: '',
  github: '',
  tags: [],
  country: '',
  firstname: '',
  lastname: '',
  username: '',
  payment_wallet: '',
};
export const SETTINGS_OBJ = {
  bio: '',
  name: '',
  website: '',
  twitter: '',
  discord: '',
  telegram: '',
  email: '',
};
export const REWARDS_OBJ = {
  title: '',
  summary: '',
  rewardAmount: '',
  rewardToken: '',
  deadline: '',
  reviewers: [
    {
      reviewer: '',
      weight: '',
      assignedCriterias: [],
    },
  ],
  criterias: [
    {
      title: '',
      score: '',
      description: '',
    },
  ],
};

export const CHALLENGE_OBJ = {
  title: '',
  summary: '',
  rewardAmount: '',
  rewardToken: '',
  deadline: getNow(),
  reviewers: [
    {
      criterias: [],
      reviewer: '',
      weight: '',
      verified: false,
      verifying: true,
      invalid: false,
      assignedCriterias: [],
    },
  ],
  criterias: [
    {
      title: '',
      score: '',
      description: '',
    },
  ],
};

export const SUBMISSIONS_OBJ = {
  proposalId: '',
  email: '',
  details: '',
};
