// import { EthereumClient, modalConnectors, walletConnectProvider } from "@web3modal/ethereum";
// import { EthereumClient } from '@web3modal/ethereum';
// import { signMessage, type SignMessageResult } from '@wagmi/core';
import { createAppKit } from '@reown/appkit/react';
import { polygon, mainnet, sepolia, AppKitNetwork, arbitrum } from '@reown/appkit/networks';
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
// import { Chain } from 'wagmi';
import { signMessage, getAccount } from '@wagmi/core';
// import { Chain } from '@reown/appkit/networks';

// import { chain, configureChains, createClient } from "wagmi";
// import { createConfig, http } from '@wagmi/core'

//To add support for another chain just import from wagmi/chains and add in this array and add chain id in env
const projectId = process.env.REACT_APP_WALLET_CONNECT_ID || '';

const chainValue = parseInt(process.env.REACT_APP_CHAIN_ID) || 137;

// Convert unsupported Mumbai chainId to Sepolia since Mumbai isn't supported by WalletConnect
const effectiveChainValue = chainValue === 80001 ? 11155111 : chainValue;

// const polygonAmoy: Chain = {
//   id: 80002, // Replace with the actual chain ID
//   name: 'Amoy',
//   nativeCurrency: {
//     name: 'MATIC',
//     symbol: 'MATIC',
//     decimals: 18,
//   },
//   rpcUrls: {
//     alchemy: {
//       http: ['https://polygon-amoy.g.alchemy.com/v2/'],
//       webSocket: ['wss://polygon-amoy.g.alchemy.com/v2/'],
//     },
//     infura: {
//       http: ['https://polygon-amoy.infura.io/v3/'],
//       webSocket: ['wss://polygon-amoy.infura.io/ws/v3/'],
//     },
//     default: {
//       http: ['https://rpc.ankr.com/polygon_amoy'],
//     },
//     public: {
//       http: ['https://rpc.ankr.com/polygon_amoy'],
//     },
//   },
//   blockExplorers: {
//     default: { name: 'PolygonScan', url: 'https://amoy.polygonscan.com/' }, // Replace with the actual block explorer URL
//   },
//   testnet: true, // Set to true if it's a testnet
// };

const networks: AppKitNetwork[] = [mainnet, arbitrum, polygon, sepolia];

// Filter supported chains - only include chains supported by WalletConnect
export const supportedChains: AppKitNetwork[] = networks.filter(
  (chain) => chain.id === effectiveChainValue,
);

// const metadata = {
//   name: 'Your App Name',
//   description: 'Your App Description',
//   url: 'https://yourapp.com',
//   icons: ['https://yourapp.com/icon.png']
// };

// Create Wagmi adapter - make sure we only pass supported networks
export const wagmiAdapter = new WagmiAdapter({
  networks: networks.filter((network) => network.id !== 80001), // Explicitly exclude Mumbai
  projectId,
});

// Create AppKit instance
export const appKit = createAppKit({
  adapters: [wagmiAdapter],
  networks:
    supportedChains.length > 0
      ? [supportedChains[0], ...networks]
      : [networks[0], ...networks.slice(1)],
  projectId,
  metadata: {
    name: 'Lunor Quest',
    description:
      'Join Lunor.Quest to crowdsource solutions at every step of the AI pipeline. Solve real-world challenges and unlock groundbreaking results.',
    url: window.location.origin,
    icons: [require('../../assets/Lunor.png')],
  },
  features: {
    email: false,
    socials: ['google', 'github', 'discord'],
    emailShowWallets: true,
  },
  featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
  ],
  includeWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
  ],
  themeMode: 'dark',
});

//Signature helper

// export const signRandomMessage = async (message: string) => {
//   try {
//     const res: SignMessageResult = await signMessage({ message });
//     return res;
//   } catch (error) {
//     return null;
//   }
// };

// Signature helper
export const signRandomMessage = async (message: string) => {
  const account = getAccount(wagmiAdapter.wagmiConfig);
  const res = await signMessage(wagmiAdapter.wagmiConfig, { message, account: account.address });
  // console.log('signed res', res);
  // console.log('account', account);
  return { res, account };
};

// export const config = createConfig({
//   chains: [mainnet, sepolia],
//   transports: {
//     [mainnet.id]: http(),
//     [sepolia.id]: http(),
//   },
// })
