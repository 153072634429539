import { TabItemObj } from '../../@types';

export const challengeDetailsTabs: TabItemObj[] = [
  {
    key: 0,
    title: 'Overview',
  },
  {
    key: 1,
    title: 'Datasets',
  },
  {
    key: 2,
    title: 'Submissions',
  },
  // {  TODO: Remove this to show tabs
  //   key: 3,
  //   title: 'Ownership',
  // },
  // {
  //   key: 4,
  //   title: 'License',
  // },
];

export const SUBMISSION_COMPLETED = 1;
export const SUBMISSION_IN_REVIEW = 0;

export const CHALLENGE_COMPLETED = 1;
export const CHALLENGE_IN_REVIEW = 0;
export const CHALLENGE_IN_DRAFT = 2;

export const CHALLENGE_ACTIVE = 'active';
export const CHALLENGE_REVIEW = 'in review';
export const CHALLENGE_EXPIRED = 'completed';
export const DRAFT_CHALLENGE = 'draft';

export const submissionBadgeColors = {
  [SUBMISSION_COMPLETED]: 'info',
  [SUBMISSION_IN_REVIEW]: 'warning',
};

export const challengeBadgeColors = {
  [CHALLENGE_ACTIVE]: 'info',
  [CHALLENGE_EXPIRED]: 'failure',
  [DRAFT_CHALLENGE]: 'warning',
  [CHALLENGE_REVIEW]: 'warning',
};

export const submissionTabs = [
  { key: 0, title: 'In-Review' },
  { key: 1, title: 'Completed' },
];

export const oceanMarketplaceURL = `https://market.oceanprotocol.com/asset/`;
export const assetTutorialURL =
  'https://docs.desights.ai/guides/checking-if-your-asset-url-is-correct?';

export const commonAcceptableFileTypes = [
  'audio/mpeg', // MP3
  'video/mp4', // MP4
  'text/plain', // Plain Text
  'application/json', // JSON
  'application/pdf', // PDF
  'image/jpeg', // JPEG
  'image/png', // PNG
  'image/gif', // GIF
  'image/bmp', // BMP
  'image/tiff', // TIFF
  'image/svg+xml', // SVG
  'application/python', // Python (py)
  'application/javascript', // JavaScript (js)
  'text/csv', // CSV
  'application/rar', // RAR
  'application/zip', // ZIP
  'application/msword', // MS Word (doc)
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // MS Word (docx)
  'application/vnd.ms-excel', // MS Excel (xls)
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // MS Excel (xlsx)
  'application/rtf', // Rich Text Format (rtf)
  'application/vnd.oasis.opendocument.text', // OpenDocument Text (odt)
  'image/gif', // GIF
  'image/bmp', // BMP
  'image/tiff', // TIFF
  'image/svg+xml', // SVG
  'application/vnd.ms-powerpoint', // MS PowerPoint (ppt)
  `application/octet-stream`,
];
