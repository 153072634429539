import { Modal } from 'flowbite-react';
import { IoClose } from 'react-icons/io5';
import { CommonModalProps } from '../@types';

const CommonModal = ({
  showModal,
  setShowModal = () => {},
  size,
  children,
  title,
  description,
  className,
  showClose,
  bodyClassName,
  closeClassName,
}: CommonModalProps) => {
  return (
    <Modal
      className={className + ' !h-full common-modal [&>div>div]:!rounded-[24px]'}
      show={showModal}
      size={size}
    >
      <Modal.Body className={bodyClassName + ' !px-6 !pb-6 !pt-3 self-center'}>
        {title && <div className='text-lg font-medium dark:text-white'>{title}</div>}
        {description && <div className='text-xs text-gray-500'>{description}</div>}
        {showClose && (
          <IoClose
            className={
              closeClassName +
              'text-2xl cursor-pointer text-black dark:text-white hover:text-primary-600 dark:hover:text-primary-500 absolute right-2 top-2'
            }
            onClick={() => {
              localStorage.removeItem('persistedProvider');
              setShowModal(false);
            }}
          />
        )}
        <div>{children}</div>
      </Modal.Body>
    </Modal>
  );
};
export default CommonModal;
