import React from 'react';
import { motion } from 'framer-motion';
import Lunor from '../assets/Lunor.png';

interface QuestSpinnerProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
}

const sizeClasses = {
  xs: 'w-4 h-4',
  sm: 'w-6 h-6',
  md: 'w-8 h-8',
  lg: 'w-12 h-12',
  xl: 'w-16 h-16',
};

const QuestSpinner: React.FC<QuestSpinnerProps> = ({ size = 'md', className = '' }) => {
  return (
    <motion.div
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`fixed inset-0 z-30 flex items-center justify-center bg-white dark:bg-black ${className}`}
    >
      <motion.img
        src={Lunor}
        alt='Loading...'
        className={`${sizeClasses[size]}`}
        animate={{
          scale: [1, 1.2, 1],
          rotate: [0, 360],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: 'easeInOut',
        }}
      />
    </motion.div>
  );
};

export default QuestSpinner;
