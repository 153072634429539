import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SlWallet } from 'react-icons/sl';
import { Spinner } from 'flowbite-react';
import { useAccount } from 'wagmi';
import { isMobile } from 'react-device-detect';
import { IoCopyOutline } from 'react-icons/io5';

import {
  fetchuserProfileId,
  fetchUserProfileData,
  formatAddress,
  isUserProfileRegistered,
  // createUserInDB,
  signRandomMessage,
  verifySignatureAndReturnAddress,

  // isWalletInvited,
} from '../Utils/Helpers';
import { AppDispatch, RootState } from '../@types';
import { ethers } from 'ethers';
import {
  updateGuestStateAction,
  updateProfileDetailsAction,
  fetchUserOrgsAction,
} from '../Store/Actions';
import {
  ADDRESS_NOT_VALID,
  CHALLENGE_PATH,
  GUEST_PROFILE_CREATE_PATH,
  // HERO_PATH,
  LIST_PATH,
  SHARED_PATH,
  // SIGNUP_PATH,
  USER_CHALLENGE_LIST_PATH,
  USER_PROFILE_DETAILS_PATH,
  USER_SUBMISSIONS_LIST_PATH,
  WALLET_BUTTON_WARNING_TEXT,
  WALLET_CONNECT_WARNING_TEXT,
} from '../Utils/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateMember } from '../Store/Slices/MemberSlice';
import {
  // updateGuestState,
  updateInitialLoading,
  updateOrgSelected,
} from '../Store/Slices/UtilsSlice';
import { toggleSignUpModalAction, toggleSignUpModalForInfoAction } from '../Store/Slices/MiscSlice';
import { BiWallet } from 'react-icons/bi';
import { resetRootState } from '../Store';
// import { profileReset } from '../Store/Slices/ProfileSlice';
import CustomToast from '../Components/CustomToast';
import CommonWarningModal from './Modals/CommonWarningModal';
import { useDisconnect } from 'wagmi';
import { useSignInTracking } from '../Utils/Hooks/useSignInTracking';
import { setCookie } from '../Utils/Helpers/CommonHelpers';
import { useAppKit } from '@reown/appkit/react';
import CommonButton from './CommonButton';

interface ConnectWalletProps {
  buttonText: string;
  responsive?: boolean;
  setLoginError?: (value: boolean) => void;
  // setShowSignUpModal?: (value: boolean) => void;
  onClick?: () => void;
  isCollapsed?: boolean;
  loading?: boolean;
  addressShow?: boolean;
}

const ConnectWallet = ({
  buttonText,
  setLoginError,
  // setShowSignUpModal,
  onClick,
  isCollapsed,
  loading = false,
  addressShow = true,
}: ConnectWalletProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);

  const defaultProfile = useSelector((state: RootState) => state.profile.defaultProfile);
  const isOrgSelected = useSelector((state: RootState) => state.utils.isOrgSelected);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const { open } = useAppKit();
  const dispatch: AppDispatch = useDispatch();
  // const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  const { trackSignIn } = useSignInTracking();
  const { disconnect } = useDisconnect();
  const {
    isConnected,
    address: userAddress,
    status,
    isConnecting,
  } = useAccount({
    // onConnect: async ({ address }) => {
    //   dispatch(toggleSignUpModalAction(false));
    //   const userProfileId = await fetchuserProfileId(address);
    //   await trackSignIn({
    //     provider: 'wallet',
    //     status: 'success',
    //     email: '',
    //     userId: userProfileId || address || '',
    //   });
    // },
    // onDisconnect: async () => {
    //   dispatch(resetRootState());
    //   await trackSignIn({
    //     provider: 'wallet',
    //     status: 'failed',
    //     email: '',
    //     userId: '',
    //   });
    // },
  });

  useEffect(() => {
    const handleConnection = async () => {
      if (isConnected && userAddress) {
        dispatch(toggleSignUpModalAction(false));
        const userProfileId = await fetchuserProfileId(userAddress);

        // console.log('Wallet Connection Data:', {
        //   isConnected,
        //   walletAddress: userAddress,
        //   userProfileId,
        //   status,
        //   timestamp: new Date().toISOString(),
        // });

        await trackSignIn({
          provider: 'wallet',
          status: 'success',
          email: '',
          userId: userProfileId || userAddress || '',
        });
      }
    };

    handleConnection();
  }, [isConnected, userAddress]);

  const connectWallet = async () => {
    // console.log('ConnectWallet: Starting wallet connection');
    dispatch(resetRootState());

    if (isMobile) {
      setShowWarningModal(true);
      return;
    }

    try {
      if (onClick) {
        await onClick();
      }
      await open();
    } catch (error) {
      console.error('ConnectWallet: Error:', error);
      setLoginError?.(true);
      dispatch(toggleSignUpModalAction(true));
    }
  };
  const handleUndeplyedUser = async () => {
    const userProfileId = await fetchuserProfileId(userAddress);
    // const isRegistered = await isUserProfileRegistered(userProfileId);
    // if (isRegistered) {
    await trackSignIn({
      provider: 'wallet',
      status: 'success',
      email: '',
      userId: userProfileId,
    });
    // return;
    // }
    dispatch(updateInitialLoading(true));
    // const undeployedUser = await verifySignatureAndReturnUser(userAddress);
    // if (undeployedUser) {
    const userProfileData = await fetchUserProfileData(userProfileId);
    // @ts-expect-error mixed type
    if (!userProfileData.username) {
      dispatch(toggleSignUpModalForInfoAction(true));
    }
    dispatch(updateProfileDetailsAction({ ...userProfileData, id: userProfileData?.profileId }));
    // }
    dispatch(updateInitialLoading(false));
    dispatch(updateGuestStateAction(false));
    if (location.pathname.includes(CHALLENGE_PATH + LIST_PATH)) {
      /**If users login from challenge list path */
      navigate(USER_CHALLENGE_LIST_PATH);
      return;
    } else {
      navigate(location.pathname);
      return;
    }
  };
  const fetchUserProfile = async () => {
    // dispatch(profileReset());
    dispatch(updateInitialLoading(true));
    // const userInvited = await isWalletInvited(userAddress, process.env.REACT_APP_REGISTRY);
    const userProfileId = await fetchuserProfileId(userAddress);
    if (!userProfileId) {
      dispatch(updateInitialLoading(false));
      //   dispatch(updateGuestState(true));
      //   if (!isGuest) {
      //     // navigate(SIGNUP_PATH);
      //   }
      return;
    }

    const isValidAddress = ethers.isAddress(userProfileId);
    if (!isValidAddress) {
      return;
    }
    if (isValidAddress) {
      const isRegistered = await isUserProfileRegistered(userProfileId);
      if (!isRegistered) {
        dispatch(updateInitialLoading(false));
        return;
      }
      if (isRegistered) {
        const userProfileData = await fetchUserProfileData(userProfileId);
        //@ts-expect-error mixed type
        if (!userProfileData.username) {
          dispatch(toggleSignUpModalForInfoAction(true));
        }
        dispatch(fetchUserOrgsAction(userProfileId));
        dispatch(updateGuestStateAction(false));
        dispatch(updateMember(userProfileData));
        dispatch(updateInitialLoading(false));
        if (!isOrgSelected) {
          /**This block runs when org is not selected and page refreshes or user logins and is registered*/
          dispatch(
            updateProfileDetailsAction({ ...userProfileData, id: userProfileData?.profileId }),
          );
          if (location.pathname.includes(CHALLENGE_PATH + LIST_PATH)) {
            /**If users login from challenge list path */
            navigate(USER_CHALLENGE_LIST_PATH);
            return;
          } else {
            navigate(location.pathname);
            return;
          }
          navigate(USER_PROFILE_DETAILS_PATH + '/' + userProfileId);
          // navigate(USER_PROFILE_DETAILS_PATH + "/" + userProfileId);
        }
        if (userProfileData.profileId !== defaultProfile.profileId) {
          // Account switched.
          if (isOrgSelected) {
            // User in the org profile.
            dispatch(updateOrgSelected(false));
          }
          dispatch(
            updateProfileDetailsAction({ ...userProfileData, id: userProfileData?.profileId }),
          );
          navigate(USER_PROFILE_DETAILS_PATH + '/' + userProfileId);
        }
        if (location.pathname.includes(SHARED_PATH)) {
          // User have account and visiting shared path
          navigate(location.pathname.replace(SHARED_PATH, ''));
        }
        if (
          location.pathname === ADDRESS_NOT_VALID ||
          location.pathname === GUEST_PROFILE_CREATE_PATH
        ) {
          // User have account and seeing the address not valid screen or user create form.
          navigate(USER_SUBMISSIONS_LIST_PATH);
        }

        return;
      }
      // If user is not registered
    }
    // else if (userInvited && location.pathname === ADDRESS_NOT_VALID) {
    //   /**  These two happens when the wallet switched on the @ADDRESS_NOT_VALID and @GUEST_PROFILE_CREATE_PATH */
    //   navigate(SIGNUP_PATH);
    // } else if (!userInvited && location.pathname === GUEST_PROFILE_CREATE_PATH) {
    //   navigate(ADDRESS_NOT_VALID);
    // }
    dispatch(updateGuestStateAction(true));
    dispatch(updateInitialLoading(false));
  };

  useEffect(() => {
    // Handle disconnect immediately
    if (!isConnected && !localStorage.getItem('isLoggingOut')) {
      dispatch(resetRootState());
      dispatch(updateGuestStateAction(true));
      setLoginError?.(false);
      dispatch(toggleSignUpModalAction(true));

      // Clear relevant storage
      localStorage.removeItem('currAddress');
      setCookie('socialLogin', null);
      return;
    }

    const handleWalletConnection = async () => {
      // Skip if we're in the process of logging out
      if (localStorage.getItem('isLoggingOut')) {
        return;
      }

      // Only proceed if we have a valid connection
      if (
        isConnected &&
        userAddress &&
        status === 'connected' &&
        userAddress !== localStorage.getItem('currAddress')
      ) {
        try {
          localStorage.setItem('currAddress', userAddress);
          dispatch(resetRootState());

          const message = `action: Login \ntimestamp: ${new Date().toISOString()}`;
          const signatureResponse = await signRandomMessage(message);

          if (!signatureResponse) {
            throw new Error('Failed to sign message');
          }

          const payload = await verifySignatureAndReturnAddress(
            message,
            signatureResponse.res,
            signatureResponse.account.address,
            signatureResponse.account.connector.name,
            localStorage.getItem('@appkit-wallet/EMAIL'),
          );

          const { walletAddress, jwt_token } = payload;
          setCookie('socialLogin', jwt_token);

          if (walletAddress !== userAddress) {
            throw new Error('Wallet address mismatch');
          }

          await fetchUserProfile();
          await handleUndeplyedUser();
        } catch (error) {
          console.error('Wallet connection error:', error);
          disconnect();
          setLoginError?.(true);
          dispatch(toggleSignUpModalAction(true));
          dispatch(updateInitialLoading(false));
        }
      }
    };

    handleWalletConnection();
  }, [isConnected, userAddress, status]);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [showToast]);

  // useEffect(() => {
  //setting as false when when wallet address changes only user will be logged in for sure
  // dispatch(updateOrgSelected(false));
  // }, [userAddress]);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowToast(true); // Reuse existing toast
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  // Add state for initial render
  const [hasInitiallyRendered, setHasInitiallyRendered] = useState(false);

  // Add effect to handle initial render
  useEffect(() => {
    if (!hasInitiallyRendered) {
      setHasInitiallyRendered(true);
    }
  }, []);

  // Modify the render logic to handle initial state
  if (!hasInitiallyRendered) {
    return null; // Don't render anything on first render
  }

  return (
    <>
      <CommonWarningModal
        buttonText={WALLET_BUTTON_WARNING_TEXT}
        show={showWarningModal}
        warningText={WALLET_CONNECT_WARNING_TEXT}
        onButtonClick={() => {
          setShowWarningModal(false);
        }}
      />
      {!isConnected && !isConnecting ? (
        <CommonButton
          buttonText={buttonText}
          ButtonIcon={SlWallet}
          iconClassName={`order-first ${isCollapsed ? '' : 'mr-2'}`}
          buttonClassName={`self-center text-md bg-tabColor !bg-primary-700 !text-lightTextColor mx-auto ${
            loading ? 'opacity-50' : ''
          }`}
          size={isCollapsed ? 'xs' : 'md'}
          disabled={loading}
          onClick={connectWallet}
        />
      ) : addressShow ? (
        <div
          className={`self-center flex w-fit gap-2 text-lightTextColor dark:text-darkTextColor rounded-lg border text-base font-medium border-primary-700 !px-2  py-2 shadow shadow-primary-200/50 dark:shadow-primary-600/50 ${
            isCollapsed ? 'hidden' : ''
          }`}
        >
          {loading ? (
            <Spinner size='sm' className='self-center' />
          ) : (
            <>
              <BiWallet className='text-primary-400 self-center text-xl md:text-sm' />
              <span className='!text-xs lg:!text-sm'>{formatAddress(userAddress)}</span>

              <button
                onClick={() => copyToClipboard(userAddress)}
                className='hover:text-primary-600 transition-colors duration-200'
                title='Copy address'
              >
                <IoCopyOutline className='text-lg md:text-sm' />
              </button>
            </>
          )}
        </div>
      ) : (
        ''
      )}
      {showToast && (
        <CustomToast text={'Address copied to clipboard'} setShowToast={setShowToast} />
      )}
    </>
  );
};

export default ConnectWallet;
