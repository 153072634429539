import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showSignUpModal: false,
  showSignUpModalForInfo: false,
};
const MiscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    toggleSignUpModalAction: (state, { payload }) => {
      state.showSignUpModal = payload;
    },
    toggleSignUpModalForInfoAction: (state, { payload }) => {
      state.showSignUpModalForInfo = payload;
    },
  },
});

export default MiscSlice.reducer;
export const { toggleSignUpModalAction, toggleSignUpModalForInfoAction } = MiscSlice.actions;
