import ReactDOMClient from 'react-dom/client';
import { Flowbite } from 'flowbite-react';
import App from './App';
import './index.css';
import { CustomFlowbiteTheme } from 'flowbite-react';
const customTheme: CustomFlowbiteTheme = {
  sidebar: {
    root: {
      inner:
        'h-full overflow-y-auto overflow-x-hidden rounded-[22px] bg-white px-3 py-4 dark:bg-primary-800 transition-all duration-300',
    },
    cta: {
      base: 'bg-transparent',
    },
    item: {
      base: 'flex items-center justify-center rounded-[22px] p-2 text-base text-lightTextColor dark:text-headingColor font-weight-400 !bg-transparent hover:!bg-transparent',
      active: '!text-lightTextColor dark:text-primary-700',
      content: {
        base: 'flex-1 whitespace-nowrap px-3',
      },
      icon: {
        base: 'h-6 w-6 flex-shrink-0 text-lightTextColor transition duration-75 group-hover/item:text-lightTextColor dark:text-headingColor dark:group-hover/item:!text-primary-700 transition-colors duration-300',
        active: 'text-lightTextColor dark:text-primary-700',
      },
    },
    itemGroup: {
      base: 'border-none',
    },
  },
  listGroup: {
    item: {
      base: 'w-full !rounded-[22px] hover:!bg-primary-50 dark:hover:!bg-darkSidebarItem overflow-hidden duration-300 transition-colors',
    },
  },
  avatar: {
    root: {
      base: 'flex items-center justify-center space-x-4',
      bordered: 'p-1 ring-2',
      rounded: 'rounded-full',
      color: {
        dark: 'ring-gray-800 dark:ring-gray-800',
        failure: 'ring-red-500 dark:ring-red-700',
        gray: 'ring-gray-500 dark:ring-gray-400',
        info: 'ring-primary-500 dark:ring-primary-500',
        light: 'ring-gray-200 dark:ring-gray-500',
        purple: 'ring-purple-500 dark:ring-purple-600',
        success: 'ring-green-500 dark:ring-green-500',
        warning: 'ring-yellow-300 dark:ring-yellow-400',
      },
      img: {
        base: 'rounded-full',
        off: 'relative overflow-hidden bg-gray-100 dark:bg-gray-600',
        placeholder: 'absolute w-auto h-auto text-gray-400 -bottom-1',
      },
      size: {
        xs: 'w-6 h-6',
        sm: 'w-8 h-8',
        md: 'w-10 h-10',
        lg: 'w-20 h-20',
        xl: 'w-36 h-36',
      },
      stacked: 'ring-2 ring-gray-200 dark:ring-gray-800',
      statusPosition: {
        'bottom-left': '-bottom-1 -left-1',
        'bottom-right': '-bottom-1 -right-1',
        'top-left': '-top-1 -left-1',
        'top-right': '-top-1 -right-1',
      },
      status: {
        away: 'bg-yellow-400',
        base: 'absolute h-3.5 w-3.5 rounded-full border-2 border-white dark:border-gray-800',
        busy: 'bg-red-400',
        offline: 'bg-gray-400',
        online: 'bg-green-400',
      },
      initials: {
        base: 'flex items-center justify-center font-medium text-gray-600 dark:text-gray-300',
        text: 'text-base',
      },
    },
  },
  modal: {
    root: {
      base: 'fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full bg-black/50 flex items-center justify-center',
      show: {
        on: 'flex',
        off: 'hidden',
      },
      sizes: {
        sm: 'max-w-sm',
        md: 'max-w-md',
        lg: 'max-w-lg',
        xl: 'max-w-xl',
        '2xl': 'max-w-2xl',
        '3xl': 'max-w-3xl',
        '4xl': 'max-w-4xl',
        '5xl': 'max-w-5xl',
        '6xl': 'max-w-6xl',
        '7xl': 'max-w-7xl',
      },
    },
    content: {
      base: 'relative h-full w-full p-4 md:h-auto',
      inner:
        'relative rounded-[22px] bg-white shadow dark:bg-primary-800 flex flex-col max-h-[90vh]',
    },
    body: {
      base: 'p-6 flex-1 overflow-auto',
      popup: 'pt-0',
    },
    header: {
      base: 'flex items-start justify-between rounded-t p-5',
      popup: 'p-2 border-b border-gray-200 dark:border-gray-700',
      title: 'text-xl font-medium text-gray-900 dark:text-white',
      close: {
        base: 'ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white',
        icon: 'h-5 w-5',
      },
    },
    footer: {
      base: 'flex items-center space-x-2 rounded-b border-t border-gray-200 p-6 dark:border-gray-700',
      popup: 'border-t',
    },
  },
  table: {
    root: {
      base: 'w-full text-left text-sm text-gray-500 dark:text-gray-400',
      shadow:
        'absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white drop-shadow-md dark:bg-black',
      wrapper: 'relative',
    },
    body: {
      base: 'group/body',
      cell: {
        base: 'px-6 py-4 group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg',
      },
    },
    head: {
      base: 'group/head text-xs uppercase text-gray-700 dark:text-gray-400',
      cell: {
        base: 'bg-gray-50 px-6 py-3 group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg dark:bg-gray-700',
      },
    },
    row: {
      base: 'group/row',
      hovered: 'hover:bg-gray-50 dark:hover:bg-primary-800',
      striped: 'odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700',
    },
  },
  button: {
    base: 'group flex items-center justify-center p-0.5 text-center font-medium relative focus:z-10 focus:outline-none transition-all duration-300',
    fullSized: 'w-full',
    color: {
      info: 'text-white bg-primary-700 border border-transparent enabled:hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:enabled:hover:bg-primary-700 dark:focus:ring-primary-800',
      failure:
        'text-white bg-red-700 border border-transparent enabled:hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:enabled:hover:bg-red-700 dark:focus:ring-red-800',
      warning:
        'text-white bg-yellow-400 border border-transparent enabled:hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 dark:bg-yellow-600 dark:enabled:hover:bg-yellow-700 dark:focus:ring-yellow-800',
      secondary:
        'text-white bg-secondary-500 border border-transparent enabled:hover:bg-secondary-600 focus:ring-4 focus:ring-secondary-300 dark:bg-secondary-600 dark:enabled:hover:bg-secondary-700 dark:focus:ring-secondary-800',
    },
    disabled: 'cursor-not-allowed opacity-50',
    isProcessing: 'cursor-wait',
    spinnerSlot: 'absolute h-full top-0 flex items-center animate-fade-in',
    spinnerLeftPosition: {
      xs: 'left-2',
      sm: 'left-3',
      md: 'left-4',
      lg: 'left-5',
      xl: 'left-6',
    },
    gradient: {
      cyan: 'text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800',
      failure:
        'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800',
      info: 'text-white bg-gradient-to-r from-primary-500 via-primary-600 to-primary-700 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-800',
      success:
        'text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800',
      warning:
        'text-white bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-yellow-300 dark:focus:ring-yellow-800',
    },
    gradientDuoTone: {
      cyanToBlue:
        'text-white bg-gradient-to-r from-cyan-500 to-primary-500 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800',
      greenToBlue:
        'text-white bg-gradient-to-br from-green-400 to-primary-600 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:ring-green-200 dark:focus:ring-green-800',
      pinkToOrange:
        'text-white bg-gradient-to-br from-pink-500 to-orange-400 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:ring-pink-200 dark:focus:ring-pink-800',
      purpleToBlue:
        'text-white bg-gradient-to-br from-purple-600 to-primary-500 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:ring-purple-200 dark:focus:ring-purple-800',
      purpleToPink:
        'text-white bg-gradient-to-r from-purple-500 to-pink-500 enabled:hover:bg-gradient-to-l focus:ring-4 focus:ring-purple-200 dark:focus:ring-purple-800',
      tealToLime:
        'text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 enabled:hover:bg-gradient-to-l enabled:hover:from-teal-200 enabled:hover:to-lime-200 enabled:hover:text-gray-900 focus:ring-4 focus:ring-lime-200 dark:focus:ring-teal-700',
    },
    inner: {
      base: 'flex items-center transition-all duration-200',
      position: {
        none: '',
        start: 'rounded-r-none',
        middle: 'rounded-none',
        end: 'rounded-l-none',
      },
    },
    label:
      'ml-2 inline-flex h-4 w-4 items-center justify-center rounded-full bg-primary-200 text-xs font-semibold text-primary-800',
    outline: {
      color: {
        gray: 'border border-gray-900 dark:border-white',
        info: 'border-2 border-primary-700 dark:border-primary-700',
        light: 'border border-gray-300 dark:border-gray-600',
      },
      off: '',
      on: 'flex justify-center bg-white text-gray-900 transition-all duration-75 ease-in group-enabled:group-hover:bg-opacity-0 group-enabled:group-hover:text-inherit dark:bg-gray-900 dark:text-white w-full',
      pill: {
        off: 'rounded-lg',
        on: 'rounded-full',
      },
    },
    pill: {
      off: 'rounded-lg',
      on: 'rounded-full',
    },
    size: {
      xs: 'text-xs p-1.5',
      sm: 'text-sm px-3 py-1.5',
      md: 'text-sm px-4 py-2',
      lg: 'text-base px-5 py-2.5',
      xl: 'text-base px-6 py-3',
    },
  },
  buttonGroup: {
    base: 'inline-flex',
    position: {
      none: '',
      start: 'rounded-r-none focus:ring-2',
      middle: 'rounded-none border-l-0 pl-0 focus:ring-2',
      end: 'rounded-l-none border-l-0 pl-0 focus:ring-2',
    },
  },
  tooltip: {
    target: 'w-fit',
    animation: 'transition-opacity',
    arrow: {
      base: 'absolute z-10 h-2 w-2 rotate-45',
      style: {
        dark: 'bg-gray-900 dark:bg-gray-700',
        light: 'bg-white',
        auto: 'bg-white dark:bg-gray-700',
      },
      placement: '-4px',
    },
    base: 'absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm',
    hidden: 'invisible opacity-0',
    style: {
      dark: 'bg-gray-900 text-white dark:bg-gray-700',
      light: 'border border-gray-200 bg-white text-gray-900',
      auto: 'border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white',
    },
    content: 'relative z-20',
  },
  spinner: {
    base: 'inline animate-spin text-gray-200',
    color: {
      failure: 'fill-red-600',
      gray: 'fill-gray-600',
      info: 'fill-cyan-600',
      pink: 'fill-pink-600',
      purple: 'fill-purple-600',
      success: 'fill-green-500',
      warning: 'fill-yellow-400',
    },
    light: {
      off: {
        base: 'dark:text-gray-600',
        color: {
          failure: '',
          gray: 'dark:fill-gray-300',
          info: '',
          pink: '',
          purple: '',
          success: '',
          warning: '',
        },
      },
      on: {
        base: '',
        color: {
          failure: '',
          gray: '',
          info: '',
          pink: '',
          purple: '',
          success: '',
          warning: '',
        },
      },
    },
    size: {
      xs: 'h-3 w-3',
      sm: 'h-4 w-4',
      md: 'h-6 w-6',
      lg: 'h-8 w-8',
      xl: 'h-10 w-10',
    },
  },
};
try {
  const container = document.getElementById('root');
  if (!container) throw new Error('No root element was found in the document.');
  ReactDOMClient.createRoot(container).render(
    // <React.StrictMode>
    <Flowbite theme={{ theme: customTheme }}>
      <App />
    </Flowbite>,
    // </React.StrictMode>
  );
} catch (error) {
  console.error(error);
}
