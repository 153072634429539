import { Helmet } from 'react-helmet';
// import LunorImage from '../assets/Lunor.png';
// import { MetaHemletProps } from '../@types';

// const LunorImage = 'https://app.lunor.quest/Lunor.png';
const LunorImage = '%PUBLIC_URL%/Lunor.png';
const MetaHemletTags = (props) => {
  return (
    <Helmet defer={false}>
      {/* Basic Meta Tags */}
      <meta charSet='utf-8' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />
      <meta name='theme-color' content='#000000' />
      <meta name='robots' content='index, follow' />
      <meta name='language' content='English' />
      <meta name='revisit-after' content='7 days' />
      <meta name='author' content='Lunor Quest' />

      {/* Title */}
      {props.pageTitle && <title>{props.pageTitle}</title>}

      {/* Primary Meta Tags */}
      <meta name='title' content={props.primaryTitle} />
      <meta name='description' content={props.primaryDescription} />

      {/* Open Graph / Facebook */}
      <meta property='og:type' content={props.fbType} />
      <meta property='og:url' content={window.location.href} />
      <meta property='og:site_name' content='Lunor Quest' />
      <meta property='og:title' content={props.fbTitle} />
      <meta property='og:description' content={props.fbDescription} />
      <meta property='og:image' content={props.fbImage || LunorImage} />
      <meta property='og:locale' content='en_US' />

      {/* Twitter */}
      <meta name='twitter:card' content={props.twitterCard} />
      <meta name='twitter:url' content={window.location.href} />
      <meta name='twitter:title' content={props.twitterTitle} />
      <meta name='twitter:description' content={props.twitterDescription} />
      <meta name='twitter:image' content={props.twitterImage || LunorImage} />
      <meta name='twitter:site' content='@LunorQuest' />
      <meta name='twitter:creator' content='@LunorQuest' />

      {/* Additional Meta Tags */}
      <meta
        name='keywords'
        content='Web3, AI, Machine Learning, Data Challenges, Blockchain, Decentralized, Lunor Quest, Lunor'
      />
      <meta name='format-detection' content='telephone=no' />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='black' />
      <meta name='apple-mobile-web-app-title' content='Lunor Quest' />

      {/* Security Meta Tags */}
      {/* <meta httpEquiv='Content-Security-Policy' content='upgrade-insecure-requests' />
      <meta httpEquiv='X-Content-Type-Options' content='nosniff' />
      <meta httpEquiv='X-Frame-Options' content='SAMEORIGIN' />
      <meta httpEquiv='X-XSS-Protection' content='1; mode=block' /> */}

      {/* Favicon */}
      <link rel='icon' type='image/png' href={LunorImage} />
      <link rel='apple-touch-icon' href={LunorImage} />
    </Helmet>
  );
};

export default MetaHemletTags;
