import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CommonModal from '../CommonModal';
import { toggleSignUpModalForInfoAction } from '../../Store/Slices/MiscSlice';
import { updateOnChainUserProfile } from '../../Utils/Helpers/APIHelpers';
import { RootState } from '../../@types';
import { useNavigate } from 'react-router-dom';
// import { debounce } from 'lodash';
// import { useLazyQuery } from '@apollo/client';
// import { CHECK_USERNAME_AVAILABILITY } from '../../graphql/users';
import { debouncedCheck } from '../../Utils/Helpers/APIHelpers';
import { checkUsernameAvailabilityAPI } from '../../Utils/Helpers/APIHelpers';
import { Spinner } from 'flowbite-react';

const UserInfoModal = ({
  showSignUpModalForInfo,
  setLoading,
}: {
  showSignUpModalForInfo: boolean;
  setLoading: (value: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const currentProfile = useSelector((state: RootState) => state.profile.currentProfile);
  // console.log('currentProfile', currentProfile);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [isAvailable, setIsAvailable] = useState<boolean | null>(null);
  const [checking, setChecking] = useState(false);
  const [helperText, setHelperText] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    setFirstName(currentProfile?.firstname || '');
    setLastName(currentProfile?.lastname || '');
    // setEmail(currentProfile?.email || '');
    return () => {
      setIsAvailable(null);
      setUsername('');
    };
  }, [currentProfile]);

  useEffect(() => {
    if (showSignUpModalForInfo) {
      const emailLoginUsed = localStorage.getItem('@appkit-wallet/EMAIL_LOGIN_USED_KEY') === 'true';
      const savedEmail = localStorage.getItem('@appkit-wallet/EMAIL');

      if (emailLoginUsed && savedEmail) {
        setEmail(savedEmail);
      }
    }
  }, [showSignUpModalForInfo]);

  const checkUsernameAvailability = checkUsernameAvailabilityAPI(
    (data) => {
      setChecking(false);
      if (
        username.length >= 4 &&
        /^(?=.*[A-Za-z])[A-Za-z0-9]+$/.test(username) &&
        username.length <= 20
      ) {
        setIsAvailable(data?.isUsernameAvailable);
        setHelperText(data?.isUsernameAvailable ? 'Username is available' : 'Username is taken');
      } else {
        setIsAvailable(null);
      }
    },
    (error) => {
      console.error('Error checking username availability:', error);
      setChecking(false);
      setHelperText('Error checking username availability');
    },
  );

  const debouncedCheckUsername = debouncedCheck((username) => {
    setChecking(true);
    checkUsernameAvailability({ variables: { username } });
  }, 1000);

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setIsAvailable(null);
    setUsername(newUsername.toLowerCase());
    setChecking(false);
    setHelperText('');
    if (newUsername.length < 4) {
      setHelperText('Username must be atleast 4 characters long');
    } else if (!/^(?=.*[a-zA-Z])/.test(newUsername)) {
      setHelperText('Username must contain at least one letter');
    } else if (/[^A-Za-z0-9]/.test(newUsername)) {
      setHelperText('Username should not contain any symbols');
    } else if (newUsername.length > 20) {
      setHelperText('Username must be less than equal to 20 characters');
    } else {
      debouncedCheckUsername(newUsername);
    }
  };

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    try {
      await updateOnChainUserProfile(
        {
          ...currentProfile,
          firstname: firstName,
          lastname: lastName,
          username: username,
          name: firstName + ' ' + lastName,
          email: email,
        },
        () => {},
        navigate,
      );
      setFirstName('');
      setLastName('');
      setEmail('');
      setLoading(false);
      dispatch(toggleSignUpModalForInfoAction(false));
    } catch (error) {
      console.error('Error updating user profile:', error);
      setLoading(false);
    }
  }

  return (
    <CommonModal
      showModal={showSignUpModalForInfo}
      setShowModal={(val) => dispatch(toggleSignUpModalForInfoAction(val))}
      showClose={false}
      className='[&>div]:!w-maxcontent'
      bodyClassName='w-full !rounded-[24px] !border-[2px] !border-primary-700 dark:bg-primary-900'
      closeClassName='dark:!text-primary-700 text-xl !rounded-[50%] !border-2 !border-gray-700 dark:!border-primary-700 hover:!border-primary-700 !right-[20px] !top-[20px] !p-4'
    >
      <div className='bg-white dark:bg-primary-800 p-6 rounded-[16px] mx-auto'>
        <h2 className='text-2xl font-bold text-lightTextColor dark:text-darkTextColor mb-6'>
          Hey! What should we call you?
        </h2>
        <form className='space-y-4' onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor='firstName'
              className='block text-sm font-medium text-lightTextColor dark:text-darkTextColor pb-2'
            >
              *First Name
            </label>
            <input
              id='firstName'
              type='text'
              placeholder='John'
              className='w-full px-4 py-2 rounded-lg border border-lightBorderColor dark:border-darkBorderColor bg-transparent dark:text-white focus:ring-1 focus:ring-primary-600 focus:border-primary-600'
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div>
            <label
              htmlFor='lastName'
              className='block text-sm font-medium text-lightTextColor dark:text-darkTextColor pb-2'
            >
              Last Name
            </label>
            <input
              id='lastName'
              type='text'
              placeholder='Wick'
              className='w-full px-4 py-2 rounded-lg border border-lightBorderColor dark:border-darkBorderColor bg-transparent dark:text-darkTextColor focus:ring-1 focus:ring-primary-600 focus:border-primary-600'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium text-lightTextColor dark:text-darkTextColor pb-2'
            >
              Email
            </label>
            <input
              id='email'
              type='email'
              placeholder='example@gmail.com'
              className='w-full px-4 py-2 rounded-lg border border-lightBorderColor dark:border-darkBorderColor bg-transparent dark:text-darkTextColor focus:ring-1 focus:ring-primary-600 focus:border-primary-600'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              readOnly={localStorage.getItem('@appkit-wallet/EMAIL_LOGIN_USED_KEY') === 'true'}
            />
          </div>
          <div>
            <label
              htmlFor='Username'
              className='block text-sm font-medium text-lightTextColor dark:text-darkTextColor pb-2'
            >
              *Username
            </label>
            <div className='flex items-center gap-2'>
              <input
                id='username'
                type='text'
                placeholder='John1223'
                minLength={4}
                maxLength={20}
                className='w-full px-4 py-2 rounded-lg border border-lightBorderColor dark:border-darkBorderColor bg-transparent dark:text-darkTextColor focus:ring-1 focus:ring-primary-600 focus:border-primary-600'
                value={username}
                required
                onChange={handleUsernameChange}
              />
              <div className='w-4'>{checking && <Spinner size='md' />}</div>
            </div>
          </div>
          <div className='h-5 text-sm flex items-center gap-2'>
            <span className={`${isAvailable && username ? 'text-green-500' : 'text-red-700'}`}>
              {!username ? 'Username is required' : helperText}
            </span>
          </div>
          <button
            type='submit'
            className='w-full !bg-primary-600 text-lightTextColor py-2 rounded-lg hover:!bg-primary-700 transition-colors duration-300 disabled:opacity-50'
            disabled={checking || !isAvailable || !username}
          >
            Continue
          </button>
        </form>
      </div>
    </CommonModal>
  );
};

export default UserInfoModal;
