const LunorImage = '%PUBLIC_URL%/Lunor.png';

export const primaryMetaTags = {
  title: "Lunor Quest's | Web3 Data Challenge Platform",
  description:
    "Lunor Quest's | A Web3 platform to crowdsource solutions to toughtest AI/ML challenges",
  image: LunorImage,
};

export const FBmetaOgTags = {
  type: 'website',
  url: 'https://app.lunor.quest/',
  title: "Lunor Quest's | Web3 Data Challenge Platform",
  description:
    "Lunor Quest's | A Web3 platform to crowdsource solutions to toughtest AI/ML challenges",
  image: LunorImage,
};

export const TwitterOgTags = {
  card: 'summary_large_image',
  url: 'https://app.lunor.quest/',
  title: "Lunor Quest's | Web3 Data Challenge Platform",
  description:
    "Lunor Quest's | A Web3 platform to crowdsource solutions to toughtest AI/ML challenges",
  image: LunorImage,
};
