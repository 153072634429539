import { configureStore } from '@reduxjs/toolkit';
import OrganizationSlice, { organizationReset } from './Slices/OrganizationSlice';
import ProfileSlice, { profileReset } from './Slices/ProfileSlice';
import MemberSlice, { memberReset } from './Slices/MemberSlice';
import UtilsSlice, { utilsReset } from './Slices/UtilsSlice';
import MiscSlice from './Slices/MiscSlice';
import { AppDispatch } from '../@types';

export const resetRootState = () => async (dispatch: AppDispatch) => {
  dispatch(profileReset());
  dispatch(organizationReset());
  dispatch(memberReset());
  dispatch(utilsReset());
};

const store = configureStore({
  reducer: {
    profile: ProfileSlice,
    organization: OrganizationSlice,
    members: MemberSlice,
    utils: UtilsSlice,
    misc: MiscSlice,
  },
});

export default store;
